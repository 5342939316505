import React from 'react'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { TextArea, SubmitButton } from 'components'
import { IonCard, IonButton } from '@ionic/react'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const defaultProps = {}

function HubMessageReplyForm({ initialValues, handleSubmit, onCancel }) {
  const validationSchema = Yup.object({
    reply: Yup.string().required('Message reply is required'),
  })
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ values, errors, isSubmitting }) => {
        const inputLength = values.reply ? values.reply.length : 0
        const maxLength = 500
        return (
          <Form>
            <IonCard id="custom-counter-text-area-wrapper">
              <TextArea
                aria-label="Reply"
                disabled={isSubmitting}
                inputmode="text"
                label={false}
                maxlength={maxLength}
                name="reply"
              />
              <div className="custom-counter-text-area-footer">
                <div className="custom-counter-text-area-error">
                  {errors.reply}
                </div>
                <div className="custom-counter">{`${inputLength} / ${maxLength}`}</div>
              </div>
            </IonCard>

            <div className="reply-buttons-wrapper">
              <IonButton
                fill="outline"
                color="dark"
                onClick={() => onCancel()}
                className="hub-message-reply-button hub-message-cancel-reply-button"
              >
                Cancel
              </IonButton>
              <SubmitButton
                color="secondary"
                submitting={isSubmitting}
                className="hub-message-reply-button hub-message-send-reply-button"
              >
                Send
              </SubmitButton>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

HubMessageReplyForm.propTypes = propTypes
HubMessageReplyForm.defaultProps = defaultProps

export default React.memo(HubMessageReplyForm)
