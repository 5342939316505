import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  IonContent,
  IonGrid,
  IonRow,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonIcon,
  IonLabel,
} from '@ionic/react'
import { attachOutline, cameraOutline } from 'ionicons/icons'
import { usePhotoGallery, useFileSystem } from 'hooks'
import { ACCEPTED_FILE_TYPES_COPY, APP_PATH, UPLOAD_PAGE_TYPE } from 'config'
import { useHistory } from 'react-router-dom'

const propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  growModalHeight: PropTypes.func.isRequired,
  addFileToDocument: PropTypes.func.isRequired,
  setUploadedPhoto: PropTypes.func.isRequired,
  isMultipleSelection: PropTypes.bool,
  hubMessageId: PropTypes.number,
}

const defaultProps = {
  isMultipleSelection: false,
  hubMessageId: null,
}

function UploadModalMethodContent({
  setIsOpen,
  growModalHeight,
  addFileToDocument,
  setUploadedPhoto,
  isMultipleSelection,
  hubMessageId,
}) {
  const history = useHistory()
  const closeUploadModal = () => setIsOpen(false)
  const { getDocumentPhoto } = usePhotoGallery({
    onNeedsPermission: () => {
      closeUploadModal()
      history.push(APP_PATH.DOCUMENTS.PERMISSIONS_NEEDED)
    },
  })
  const { selectDocumentFile } = useFileSystem()

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar className="ion-text-center">
          <IonButtons slot="start">
            <IonBackButton onClick={growModalHeight} />
          </IonButtons>
          <IonTitle>Choose an Option</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-md">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <div className="sheet-modal modal-align">
              <IonButton
                onClick={async () => {
                  const photo = await getDocumentPhoto()

                  if (photo) {
                    setUploadedPhoto(photo)
                    closeUploadModal()
                    history.push(
                      `${APP_PATH.UPLOAD.CAMERA}/${
                        isMultipleSelection
                          ? UPLOAD_PAGE_TYPE.MULTIPLE.value
                          : UPLOAD_PAGE_TYPE.SINGLE.value
                      }${hubMessageId ? `/${hubMessageId}` : ''}`
                    )
                  }
                }}
                color="secondary"
              >
                <IonIcon slot="start" icon={cameraOutline}></IonIcon>
                <IonLabel slot="end">Photo</IonLabel>
              </IonButton>
              <IonButton
                onClick={async () => {
                  const file = await selectDocumentFile()
                  if (file) {
                    addFileToDocument(file)
                    closeUploadModal()
                    const fileUploadPath = `${APP_PATH.UPLOAD.FILE}/${
                      isMultipleSelection
                        ? UPLOAD_PAGE_TYPE.MULTIPLE.value
                        : UPLOAD_PAGE_TYPE.SINGLE.value
                    }${hubMessageId ? `/${hubMessageId}` : ''}`
                    history.push(fileUploadPath)
                  }
                }}
              >
                <IonIcon slot="start" icon={attachOutline}></IonIcon>
                <IonLabel slot="end">Upload Files</IonLabel>
              </IonButton>
            </div>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <small>{ACCEPTED_FILE_TYPES_COPY}</small>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  )
}

UploadModalMethodContent.propTypes = exact(propTypes)
UploadModalMethodContent.defaultProps = defaultProps

export default React.memo(UploadModalMethodContent)
