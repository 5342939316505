import {
  homeOutline,
  calendarOutline,
  documentTextOutline,
  receiptOutline,
  playCircleOutline,
  helpBuoyOutline,
  analyticsOutline,
  serverOutline,
  shieldHalfOutline,
  logoFacebook,
  logoInstagram,
  logoLinkedin,
  logoYoutube,
  logoTiktok,
  pinOutline,
  attachOutline,
  locationOutline,
  walletOutline,
} from 'ionicons/icons'
import { CellFormatter } from './main/financial-performance/components'
import React from 'react'
import { kebabCase, map, without, startCase, values } from 'lodash'
import TableRowCell from './main/financial-performance/components/TableRowCell'
import { isTotalRow } from 'utils'

export function isProduction() {
  return process.env.NODE_ENV === 'production'
}

export const FIREBASE_CONFIG = {
  API_KEY: process.env.FIREBASE_API_KEY,
  SENDER_ID: process.env.FIREBASE_SENDER_ID,
  APP_ID: process.env.FIREBASE_APP_ID,
  MEASUREMENT_ID: process.env.FIREBASE_MEASUREMENT_ID,
}

export const SMARTLOOK_CONFIG = {
  PROJECT_KEY: process.env.SMARTLOOK_PROJECT_KEY,
}

export const CURRENT_YEAR = new Date().getFullYear().toString()

export const USER_TYPE = {
  ENT: 'ENT',
  PRO: 'PRO',
  ESS: 'ESS',
  EZY: 'EZY',
  STL: 'STL',
  RIDE: 'RIDE',
}

export const NON_LIMITED_USER_TYPES = values(USER_TYPE)

export const ANY_USER = 'anyUser'
export const ANY_ACTIVE_USER = 'anyActiveUser'
export const ANY_ACTIVE_NON_RIDE_USER = 'anyActiveNonRideUser'

export const HUB_MESSAGE_DASHBOARD_LABEL = 'Messages'

export const APP_PATH = {
  ROOT: '/',
  SIGN_IN: '/sign-in',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  IMPERSONATE: '/impersonate',
  UPLOAD: {
    ROOT: '/upload',
    CAMERA: '/upload/camera-upload',
    FILE: '/upload/file-upload',
    DOCUMENT_MANAGER: '/upload/document-manager',
    SUCCESS: '/upload/success',
  },
  HOME: '/home',
  PER_DIEM: '/per-diem',
  DOCUMENTS: {
    ROOT: '/documents',
    ALL: '/documents/all-documents',
    HOW_TO_SEND: '/documents/how-to-send-documents',
    VIEW: '/documents/show',
    PERMISSIONS_NEEDED: '/documents/permissions-needed',
  },
  FINANCIAL_PERFORMANCE: {
    ROOT: '/financial-performance',
    BALANCE_SHEET: '/financial-performance/balance-sheet',
    BS_TRANSACTIONS_BATCH: '/financial-performance/balance-sheet/batch',
    BS_CATEGORY_TRANSACTIONS:
      '/financial-performance/balance-sheet/transactions',
    PROFIT_AND_LOSS: '/financial-performance/profit-and-loss',
    PL_TRANSACTIONS_BATCH: '/financial-performance/profit-and-loss/batch',
    PL_CATEGORY_TRANSACTIONS:
      '/financial-performance/profit-and-loss/transactions',
    PROFIT_PLAN: '/financial-performance/profit-plan',
  },
  TAX: {
    ROOT: '/tax',
    ESTIMATES: '/tax/quarterly-tax-estimates',
    RETURNS: '/tax/tax-returns',
  },
  RESOURCES: '/resources',
  HUB_MESSAGES: {
    ROOT: '/hub-messages',
    VIEW: '/hub-messages/show',
  },
  TUTORIALS: '/tutorials',
  MILEAGE_TRACKER: {
    ROOT: '/mileage-tracker',
    TRIP_TRACKER: '/mileage-tracker/trip-tracker',
    ALL_TRIPS: '/mileage-tracker/all-trips',
    ADD_TRIP: '/mileage-tracker/add-trip',
    EDIT_TRIP: '/mileage-tracker/edit-trip',
    LOCATION_PERMISSION_NEEDED: '/mileage-tracker/location-permission-needed',
    NOTIFICATION_PERMISSION_NEEDED:
      '/mileage-tracker/notification-permission-needed',
  },
  CONNECT_BANK: '/connect-bank',
}

export const UNAUTHENTICATED_PATHS = [
  APP_PATH.SIGN_IN,
  APP_PATH.FORGOT_PASSWORD,
  APP_PATH.RESET_PASSWORD,
  APP_PATH.IMPERSONATE,
]

export const LIMITED_USER_ALLOWED_PATHS = [
  APP_PATH.HOME,
  APP_PATH.UPLOAD.ROOT,
  APP_PATH.DOCUMENTS.ROOT,
  APP_PATH.TAX.RETURNS,
  APP_PATH.RESOURCES,
  APP_PATH.TUTORIALS,
  APP_PATH.HUB_MESSAGES.ROOT,
]

export const INACTIVE_ACCESS_HOME_PATH = APP_PATH.TAX.RETURNS

export const RESOURCES_FOLDER_ID = {
  [USER_TYPE.ENT]: '1GEZWEv7ymRf0Hm7f1I_qArwHD-Nee0Qk',
  [USER_TYPE.PRO]: '1j1fOBYyvRhCIU3-gFuyA99QytYB1L3KW',
  [USER_TYPE.ESS]: '1NmEQBbW2i0HAkEmJNq4-jFO7k_ErTji2',
  [USER_TYPE.RIDE]: '165jwZzsgqyKAkr3IsxV29fx1tb3dt-th',
}

export const RESOURCES_FOLDER_FOR_USER_TYPE = {
  [USER_TYPE.ENT]: RESOURCES_FOLDER_ID[USER_TYPE.ENT],
  [USER_TYPE.PRO]: RESOURCES_FOLDER_ID[USER_TYPE.PRO],
  [USER_TYPE.ESS]: RESOURCES_FOLDER_ID[USER_TYPE.ESS],
  [USER_TYPE.EZY]: RESOURCES_FOLDER_ID[USER_TYPE.ESS],
  [USER_TYPE.STL]: RESOURCES_FOLDER_ID[USER_TYPE.ESS],
  [USER_TYPE.RIDE]: RESOURCES_FOLDER_ID[USER_TYPE.RIDE],
}

export const YEARLY_TAX_ORGANIZER_FORM_URL =
  'https://forms.atbs.com/team/atbs/client-tax-organizer'

export const IRS_PAYMENT_URL = 'https://directpay.irs.gov/directpay/payment'

export const OPEN_MODAL_ID = 'open-modal-link'

export const MENU_ITEMS = [
  {
    name: 'Home',
    path: APP_PATH.HOME,
    icon: 'homeOutline',
    allowedUserTypes: ANY_ACTIVE_USER,
  },
  {
    name: HUB_MESSAGE_DASHBOARD_LABEL,
    path: APP_PATH.HUB_MESSAGES.ROOT,
    icon: 'hubMessagesIcon',
    allowedUserTypes: ANY_ACTIVE_USER,
  },
  {
    name: 'Per Diem',
    path: APP_PATH.PER_DIEM,
    icon: 'calendarOutline',
    allowedUserTypes: without(NON_LIMITED_USER_TYPES, USER_TYPE.RIDE),
  },
  {
    accordionGroup: true,
    menuItems: [
      {
        name: 'Mileage Tracker',
        icon: 'pinOutline',
        submenuItems: [
          {
            name: 'Trip Tracker',
            path: APP_PATH.MILEAGE_TRACKER.TRIP_TRACKER,
            allowedUserTypes: [USER_TYPE.RIDE],
          },
          {
            name: 'All Trips',
            path: APP_PATH.MILEAGE_TRACKER.ALL_TRIPS,
            allowedUserTypes: [USER_TYPE.RIDE],
          },
        ],
      },
      {
        name: 'Documents',
        icon: 'documentTextOutline',
        submenuItems: [
          {
            name: 'Upload',
            id: OPEN_MODAL_ID,
            allowedUserTypes: ANY_ACTIVE_USER,
          },
          {
            name: 'All Documents',
            path: APP_PATH.DOCUMENTS.ALL,
            allowedUserTypes: ANY_ACTIVE_USER,
          },
          // {
          //   name: 'How To Send Documents',
          //   path: APP_PATH.DOCUMENTS.HOW_TO_SEND,
          //   allowedUserTypes: ANY_ACTIVE_USER,
          // },
        ],
      },
      {
        name: 'Financial Performance',
        icon: 'analyticsOutline',
        submenuItems: [
          {
            name: 'Profit & Loss',
            path: APP_PATH.FINANCIAL_PERFORMANCE.PROFIT_AND_LOSS,
            allowedUserTypes: NON_LIMITED_USER_TYPES,
          },
          {
            name: 'Profit Plan',
            path: APP_PATH.FINANCIAL_PERFORMANCE.PROFIT_PLAN,
            allowedUserTypes: [USER_TYPE.PRO, USER_TYPE.ENT],
          },
          {
            name: 'Balance Sheet',
            path: APP_PATH.FINANCIAL_PERFORMANCE.BALANCE_SHEET,
            allowedUserTypes: [USER_TYPE.ENT],
          },
        ],
      },
      {
        name: 'Tax',
        icon: 'receiptOutline',
        submenuItems: [
          {
            name: 'Quarterly Tax Estimates',
            path: APP_PATH.TAX.ESTIMATES,
            allowedUserTypes: NON_LIMITED_USER_TYPES,
          },
          {
            name: 'Tax Returns',
            path: APP_PATH.TAX.RETURNS,
            allowedUserTypes: ANY_USER,
          },
          {
            name: 'Client Tax Organizer',
            href: YEARLY_TAX_ORGANIZER_FORM_URL,
            allowedUserTypes: ANY_USER,
            injectUserData: true,
          },
          {
            name: 'Make IRS Payment',
            href: IRS_PAYMENT_URL,
            allowedUserTypes: ANY_USER,
          },
        ],
      },
    ],
  },
  {
    name: 'Resources',
    path: APP_PATH.RESOURCES,
    icon: 'serverOutline',
    allowedUserTypes: ANY_USER,
  },
  // {
  //   name: 'Tutorials',
  //   path: APP_PATH.TUTORIALS,
  //   icon: 'playCircleOutline',
  //   allowedUserTypes: ANY_ACTIVE_NON_RIDE_USER,
  // },
  {
    name: 'Connect Bank',
    path: APP_PATH.CONNECT_BANK,
    icon: 'walletOutline',
    allowedUserTypes: [USER_TYPE.ENT],
  },
]

export const MENU_EXTERNAL_LINKS = [
  {
    name: 'Help',
    href: 'https://www.atbs.com/mobile-app',
    icon: 'helpBuoyOutline',
    allowedUserTypes: ANY_ACTIVE_NON_RIDE_USER,
  },
  {
    name: 'Privacy Policy',
    href: 'https://www.atbs.com/privacy-policy',
    icon: 'shieldHalfOutline',
  },
]

export const SOCIAL_LINK_ITEMS = [
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/go2ATBS',
    icon: 'logoFacebook',
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/go2ATBS',
    icon: 'logoInstagram',
  },
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/atbs',
    icon: 'logoLinkedin',
  },
  {
    name: 'YouTube',
    href: 'https://www.youtube.com/user/WatchATBS',
    icon: 'logoYoutube',
  },
  {
    name: 'TikTok',
    href: 'https://www.tiktok.com/@atbstruckertaxes',
    icon: 'logoTiktok',
  },
]

export const MENU_ICON = {
  homeOutline,
  calendarOutline,
  documentTextOutline,
  analyticsOutline,
  receiptOutline,
  playCircleOutline,
  helpBuoyOutline,
  serverOutline,
  shieldHalfOutline,
  logoFacebook,
  logoInstagram,
  logoLinkedin,
  logoYoutube,
  logoTiktok,
  pinOutline,
  walletOutline,
}

export const PERMISSIONS_SPLASH = {
  camera: {
    title: 'Permissions Needed',
    body: 'In order to import documents and receipts, go to your phone Settings and grant this app access. We will see photos imported into this app.',
    icon: attachOutline,
    type: 'camera',
  },
  location: {
    title: 'Location Needed',
    body: 'Permission to access your GPS location is needed for our Mileage Tracker to provide you with accurate data. This information will not be used or shared outside of the Mileage Tracker app. Click below to change your location settings or to read our privacy agreement.',
    icon: locationOutline,
    type: 'location',
  },
  notification: {
    title: 'Notification Permission Needed',
    body: 'Permission to notify you that your location is being tracked in the background is needed for our Mileage Tracker.',
    icon: locationOutline,
    type: 'notification',
  },
}

export const LOGO_COLOR = {
  LIGHT: 'light',
  DARK: 'dark',
}

export const LATEST_DOCS_LIMIT = 5

export const DOCUMENT_STATUS = {
  SIGNED: 'Submitted',
  UPLOADED: 'Submitted',
  PROCESSING: 'Working On It',
  RESEARCHING: 'Still Working',
  PROCESSED: 'All Done!',
  RECEIVED: 'All Done!',
}

export const DOCUMENT_PROCESSED_STATUS = 'processed'

export const DOCUMENT_CATEGORY = {
  RECEIVED: 'received',
  POSTED: 'posted',
}

export const UPLOAD_FLOW_TYPE = {
  CAMERA: 'camera',
  FILE: 'file',
}

export const PERMISSION = {
  GRANTED: 'granted',
  DENIED: 'denied',
  PROMPT: 'prompt',
}

export const ACCEPTED_FILE_TYPES = [
  'application/pdf',
  'application/x-pdf',
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/tiff',
]

export const ACCEPTED_FILE_TYPES_COPY =
  'Accepted file types are PDF, PNG, JPG, JPEG or TIFF.'

const FILE_SIZE_LIMIT_MB = 10
export const FILE_SIZE_LIMIT = FILE_SIZE_LIMIT_MB * 1024 * 1024
export const FILE_SIZE_LIMIT_COPY = `Size limit exceeded. File must be ${FILE_SIZE_LIMIT_MB}MB or less.`

export const BEGINNING = 'beginning'

export const MONTH = {
  JANUARY: 'january',
  FEBRUARY: 'february',
  MARCH: 'march',
  APRIL: 'april',
  MAY: 'may',
  JUNE: 'june',
  JULY: 'july',
  AUGUST: 'august',
  SEPTEMBER: 'september',
  OCTOBER: 'october',
  NOVEMBER: 'november',
  DECEMBER: 'december',
}

export const MONTHS = [
  MONTH.JANUARY,
  MONTH.FEBRUARY,
  MONTH.MARCH,
  MONTH.APRIL,
  MONTH.MAY,
  MONTH.JUNE,
  MONTH.JULY,
  MONTH.AUGUST,
  MONTH.SEPTEMBER,
  MONTH.OCTOBER,
  MONTH.NOVEMBER,
  MONTH.DECEMBER,
]

export const YTD_OPTION = {
  periodSelectorValue: 'YTD',
  apiValue: 'yearToDate',
}

export const NAME = {
  name: '',
  value: 'name',
}

export const FINANCIAL_PERFORMANCE_TABLE_COLUMN = {
  NAME,
  BEGINNING: {
    name: startCase(BEGINNING),
    periodSelectorValue: BEGINNING,
    value: `${BEGINNING}Amount`,
  },
  JANUARY: {
    name: 'Jan',
    periodSelectorValue: MONTH.JANUARY,
    value: `${MONTH.JANUARY}Amount`,
    benchmarkKey: `${MONTH.JANUARY}BenchmarkStatus`,
  },
  FEBRUARY: {
    name: 'Feb',
    periodSelectorValue: MONTH.FEBRUARY,
    value: `${MONTH.FEBRUARY}Amount`,
    benchmarkKey: `${MONTH.FEBRUARY}BenchmarkStatus`,
  },
  MARCH: {
    name: 'Mar',
    periodSelectorValue: MONTH.MARCH,
    value: `${MONTH.MARCH}Amount`,
    benchmarkKey: `${MONTH.MARCH}BenchmarkStatus`,
  },
  APRIL: {
    name: 'Apr',
    periodSelectorValue: MONTH.APRIL,
    value: `${MONTH.APRIL}Amount`,
    benchmarkKey: `${MONTH.APRIL}BenchmarkStatus`,
  },
  MAY: {
    name: 'May',
    periodSelectorValue: MONTH.MAY,
    value: `${MONTH.MAY}Amount`,
    benchmarkKey: `${MONTH.MAY}BenchmarkStatus`,
  },
  JUNE: {
    name: 'Jun',
    periodSelectorValue: MONTH.JUNE,
    value: `${MONTH.JUNE}Amount`,
    benchmarkKey: `${MONTH.JUNE}BenchmarkStatus`,
  },
  JULY: {
    name: 'Jul',
    periodSelectorValue: MONTH.JULY,
    value: `${MONTH.JULY}Amount`,
    benchmarkKey: `${MONTH.JULY}BenchmarkStatus`,
  },
  AUGUST: {
    name: 'Aug',
    periodSelectorValue: MONTH.AUGUST,
    value: `${MONTH.AUGUST}Amount`,
    benchmarkKey: `${MONTH.AUGUST}BenchmarkStatus`,
  },
  SEPTEMBER: {
    name: 'Sep',
    periodSelectorValue: MONTH.SEPTEMBER,
    value: `${MONTH.SEPTEMBER}Amount`,
    benchmarkKey: `${MONTH.SEPTEMBER}BenchmarkStatus`,
  },
  OCTOBER: {
    name: 'Oct',
    periodSelectorValue: MONTH.OCTOBER,
    value: `${MONTH.OCTOBER}Amount`,
    benchmarkKey: `${MONTH.OCTOBER}BenchmarkStatus`,
  },
  NOVEMBER: {
    name: 'Nov',
    periodSelectorValue: MONTH.NOVEMBER,
    value: `${MONTH.NOVEMBER}Amount`,
    benchmarkKey: `${MONTH.NOVEMBER}BenchmarkStatus`,
  },
  DECEMBER: {
    name: 'Dec',
    periodSelectorValue: MONTH.DECEMBER,
    value: `${MONTH.DECEMBER}Amount`,
    benchmarkKey: `${MONTH.DECEMBER}BenchmarkStatus`,
  },
  TOTAL: {
    name: 'Total',
    periodSelectorValue: YTD_OPTION.periodSelectorValue,
    value: YTD_OPTION.apiValue,
  },
}

export const PROFIT_LOSS_PERIODS = [
  FINANCIAL_PERFORMANCE_TABLE_COLUMN.JANUARY,
  FINANCIAL_PERFORMANCE_TABLE_COLUMN.FEBRUARY,
  FINANCIAL_PERFORMANCE_TABLE_COLUMN.MARCH,
  FINANCIAL_PERFORMANCE_TABLE_COLUMN.APRIL,
  FINANCIAL_PERFORMANCE_TABLE_COLUMN.MAY,
  FINANCIAL_PERFORMANCE_TABLE_COLUMN.JUNE,
  FINANCIAL_PERFORMANCE_TABLE_COLUMN.JULY,
  FINANCIAL_PERFORMANCE_TABLE_COLUMN.AUGUST,
  FINANCIAL_PERFORMANCE_TABLE_COLUMN.SEPTEMBER,
  FINANCIAL_PERFORMANCE_TABLE_COLUMN.OCTOBER,
  FINANCIAL_PERFORMANCE_TABLE_COLUMN.NOVEMBER,
  FINANCIAL_PERFORMANCE_TABLE_COLUMN.DECEMBER,
  FINANCIAL_PERFORMANCE_TABLE_COLUMN.TOTAL,
]

export const FINANCIAL_PERFORMANCE_SECTION = {
  ASSET: 'Assets',
  ASSETS: 'Assets',
  LIABILITY: 'Liabilities',
  OWNERS_EQUITY: "Owner's Equity",
  EQUITY: 'Equity',
  EXPENSE: 'Expenses',
  INCOME: 'Income',
  NON_P_L_EXPENSES: 'Non P&L Expenses',
  STATISTICAL: 'Statistical',
}

export const BALANCE_SHEET_BASE_COLUMN_SETTINGS = () => {
  return [
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.BEGINNING.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.BEGINNING.value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    },
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.JANUARY.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.JANUARY.value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    },
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.FEBRUARY.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.FEBRUARY.value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    },
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.MARCH.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.MARCH.value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    },
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.APRIL.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.APRIL.value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    },
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.MAY.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.MAY.value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    },
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.JUNE.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.JUNE.value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    },
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.JULY.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.JULY.value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    },
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.AUGUST.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.AUGUST.value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    },
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.SEPTEMBER.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.SEPTEMBER.value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    },
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.OCTOBER.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.OCTOBER.value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    },
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.NOVEMBER.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.NOVEMBER.value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    },
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.DECEMBER.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.DECEMBER.value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    },
  ]
}

export const PROFIT_PLAN_COLUMN = {
  NAME,
  WEEKLY: {
    name: 'Weekly',
    value: 'weekly',
  },
  MONTHLY: {
    name: 'Monthly',
    value: 'monthly',
  },
  ANNUAL: {
    name: 'Annual',
    value: 'annual',
  },
  CENTS_PER_MILE: {
    name: 'Cents Per Mile',
    value: 'centsPerMile',
  },
  REVENUE_PERCENTAGE: {
    name: '% Rev',
    value: 'revenuePercent',
  },
  WEEKLY_BUS_NET_INCOME: {
    name: 'Weekly Net Income',
    value: 'weeklyBusNetIncome',
  },
  WEEKLY_BUS_REVENUE: {
    name: 'Weekly Revenue',
    value: 'weeklyBusRevenue',
  },
  WEEKLY_BUS_VARIABLE: {
    name: 'Weekly Variable Expense',
    value: 'weeklyBusVariable',
  },
  WEEKLY_CHANGE: { name: 'Weekly Change', value: 'weeklyChange' },
  ANNUAL_CHANGE: { name: 'Annual Change', value: 'annualChange' },
  ANNUAL_BREAKEVEN: { name: 'Annual Breakeven', value: 'annualBreakeven' },
  ANNUAL_IMPROVEMENT: {
    name: 'Annual Improvement',
    value: 'annualImprovement',
  },
  GOAL_BREAKEVEN: { name: 'Goal Breakeven', value: 'goalBreakeven' },
  PLAN_BREAKEVEN: { name: 'Plan Breakeven', value: 'planBreakeven' },
}

export const PROFIT_PLAN_SECTION = {
  MILES: 'Miles',
  REVENUE: 'Revenue',
  VARIABLE: 'Variable',
  FIXED: 'Fixed',
  PERSONAL_OBLIGATIONS: 'Personal Obligations',
  BREAKEVEN_POINT: 'Breakeven Point',
  PER_DIEM: 'Per Diem',
  BUSINESS_WHAT_IF: 'Business What If Scenario',
  PERSONAL_WHAT_IF: 'Personal What If Scenario',
}

export const BREAKEVEN_POINT_CATEGORY = {
  MILES: 'Miles to Breakeven',
  REVENUE: 'Revenue to Breakeven',
}

export const PROFIT_LOSS_SECTION = {
  MILES: 'Miles',
  REVENUE: 'Revenue',
  VARIABLE_EXPENSES: 'Variable Expenses',
  FIXED_EXPENSES: 'Fixed Expenses',
  LIABILITIES: 'Liabilities',
}

export const WHAT_IF_FILTER = {
  BUSINESS_WHAT_IF: 'businessWhatIf',
  PERSONAL_WHAT_IF: 'personalWhatIf',
}

export const PROFIT_PLAN_BASE_FILTERS = [
  PROFIT_PLAN_COLUMN.WEEKLY,
  PROFIT_PLAN_COLUMN.MONTHLY,
  PROFIT_PLAN_COLUMN.ANNUAL,
  PROFIT_PLAN_COLUMN.CENTS_PER_MILE,
  PROFIT_PLAN_COLUMN.REVENUE_PERCENTAGE,
]

const isMilesToBreakevenRow = (row) =>
  row.original.name === BREAKEVEN_POINT_CATEGORY.MILES

const PROFIT_PLAN_HEADER_COLUMN = {
  header: PROFIT_PLAN_COLUMN.NAME.name,
  accessorKey: PROFIT_PLAN_COLUMN.NAME.value,
}

export const PROFIT_PLAN_BASE_COLUMN_SETTINGS = (isCurrency) => [
  PROFIT_PLAN_HEADER_COLUMN,
  {
    header: PROFIT_PLAN_COLUMN.WEEKLY.name,
    accessorKey: PROFIT_PLAN_COLUMN.WEEKLY.value,
    Cell: ({ cell, row }) => (
      <CellFormatter
        rawValue={cell.getValue()}
        isUnits={isMilesToBreakevenRow(row)}
        isCurrency={isCurrency}
      />
    ),
  },
  {
    header: PROFIT_PLAN_COLUMN.MONTHLY.name,
    accessorKey: PROFIT_PLAN_COLUMN.MONTHLY.value,
    Cell: ({ cell, row }) => (
      <CellFormatter
        rawValue={cell.getValue()}
        isUnits={isMilesToBreakevenRow(row)}
        isCurrency={isCurrency}
      />
    ),
  },
  {
    header: PROFIT_PLAN_COLUMN.ANNUAL.name,
    accessorKey: PROFIT_PLAN_COLUMN.ANNUAL.value,
    Cell: ({ cell, row }) => (
      <CellFormatter
        rawValue={cell.getValue()}
        isUnits={isMilesToBreakevenRow(row)}
        isCurrency={isCurrency}
      />
    ),
  },
]

export const PROFIT_PLAN_EXTENDED_COLUMN_SETTINGS = [
  {
    header: PROFIT_PLAN_COLUMN.CENTS_PER_MILE.name,
    accessorKey: PROFIT_PLAN_COLUMN.CENTS_PER_MILE.value,
    Cell: ({ cell }) => (
      <CellFormatter rawValue={cell.getValue()} isUnits={true} />
    ),
  },
  {
    header: PROFIT_PLAN_COLUMN.REVENUE_PERCENTAGE.name,
    accessorKey: PROFIT_PLAN_COLUMN.REVENUE_PERCENTAGE.value,
    Cell: ({ cell }) => (
      <CellFormatter rawValue={cell.getValue()} isPercentage={true} />
    ),
  },
]

export const BUSINESS_WHAT_IF_COLUMNS = [
  PROFIT_PLAN_COLUMN.WEEKLY_BUS_REVENUE,
  PROFIT_PLAN_COLUMN.WEEKLY_BUS_VARIABLE,
  PROFIT_PLAN_COLUMN.WEEKLY_BUS_NET_INCOME,
  PROFIT_PLAN_COLUMN.WEEKLY_CHANGE,
  PROFIT_PLAN_COLUMN.ANNUAL_CHANGE,
]
export const PERSONAL_WHAT_IF_COLUMNS = [
  PROFIT_PLAN_COLUMN.PLAN_BREAKEVEN,
  PROFIT_PLAN_COLUMN.GOAL_BREAKEVEN,
  PROFIT_PLAN_COLUMN.WEEKLY_CHANGE,
  PROFIT_PLAN_COLUMN.ANNUAL_BREAKEVEN,
  PROFIT_PLAN_COLUMN.ANNUAL_IMPROVEMENT,
]

export const BUSINESS_WHAT_IF_COLUMN_SETTINGS = [
  PROFIT_PLAN_HEADER_COLUMN,
  ...BUSINESS_WHAT_IF_COLUMNS.map(({ name, value }) => {
    // Use abbreviation for desktop table that has limited space due to multiple columns
    const displayedName =
      name === PROFIT_PLAN_COLUMN.WEEKLY_BUS_VARIABLE.name
        ? 'Weekly Variable Exp.'
        : name
    return {
      header: displayedName,
      accessorKey: value,
      Cell: ({ cell }) => (
        <CellFormatter rawValue={cell.getValue()} isCurrency={true} />
      ),
    }
  }),
]

export const PERSONAL_WHAT_IF_COLUMN_SETTINGS = [
  PROFIT_PLAN_HEADER_COLUMN,
  ...PERSONAL_WHAT_IF_COLUMNS.map(({ name, value }) => ({
    header: name,
    accessorKey: value,
    Cell: ({ cell, row }) => (
      <CellFormatter
        rawValue={cell.getValue()}
        isUnits={isMilesToBreakevenRow(row)}
        isCurrency={true}
      />
    ),
  })),
]

export const CELL_FORMATTING = {
  CURRENCY: {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  },
  PERCENTAGE: {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  UNITS: {
    maximumFractionDigits: 0,
  },
}

export const PROFIT_LOSS_AMOUNT_KEY_SUBSTRING = {
  AMOUNT: 'Amount',
  CENTS_PER_MILE: 'AmountCentsPerMile',
  REVENUE_PERCENT: 'AmountRevenuePercent',
  PERCENT_OF_TOTAL: 'AmountPercentOfTotal',
  YTD: {
    AMOUNT: 'YtdAmount',
    CENTS_PER_MILE: 'YtdAmountCentsPerMile',
    REVENUE_PERCENT: 'YtdAmountRevenuePercent',
    PERCENT_OF_TOTAL: 'YtdAmountPercentOfTotal',
  },
}

export const PROFIT_LOSS_AMOUNT_KEY_SUBSTRINGS = [
  PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.AMOUNT,
  PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.YTD.AMOUNT,
]

export const PROFIT_LOSS_MONTHLY_COLUMN = (
  month,
  isMilesSection,
  hideNonAmountData
) => {
  const displayMonth = startCase(month?.slice(0, 3))
  if (isMilesSection) {
    const amountOnlyColumns = {
      NAME,
      MONTH_AMOUNT: {
        name: `${displayMonth} Amount`,
        value: `${month}${PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.AMOUNT}`,
        benchmarkKey: `${month}BenchmarkStatus`,
      },
      YTD_AMOUNT: {
        name: 'YTD Amount',
        value: `${month}${PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.YTD.AMOUNT}`,
      },
    }

    if (hideNonAmountData) return amountOnlyColumns

    return {
      ...amountOnlyColumns,
      // Hidden column to align Miles table with others on the same page
      MONTH_SPACER: {
        type: 'hidden',
        name: 'Blank Column',
      },
      MONTH_PERCENT_TOTAL: {
        name: `${displayMonth} % Total`,
        value: `${month}${PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.PERCENT_OF_TOTAL}`,
      },
      // Hidden column to align Miles table with others on the same page
      YTD_SPACER: {
        type: 'hidden',
        name: 'Blank Column',
      },
      YTD_PERCENT_TOTAL: {
        name: 'YTD % Total',
        value: `${month}${PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.YTD.PERCENT_OF_TOTAL}`,
      },
    }
  } else {
    const amountOnlyColumns = {
      NAME,
      MONTH_AMOUNT: {
        name: `${displayMonth} Amount`,
        value: `${month}${PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.AMOUNT}`,
        benchmarkKey: `${month}BenchmarkStatus`,
      },
      YTD_AMOUNT: {
        name: 'YTD Amount',
        value: `${month}${PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.YTD.AMOUNT}`,
      },
    }

    if (hideNonAmountData) return amountOnlyColumns

    return {
      ...amountOnlyColumns,
      MONTH_CENTS_PER_MILE: {
        name: `${displayMonth} Cents Per Mile`,
        value: `${month}${PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.CENTS_PER_MILE}`,
      },
      MONTH_PERCENTAGE_REVENUE: {
        name: `${displayMonth} % Rev`,
        value: `${month}${PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.REVENUE_PERCENT}`,
      },
      YTD_CENTS_PER_MILE: {
        name: 'YTD Cents Per Mile',
        value: `${month}${PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.YTD.CENTS_PER_MILE}`,
      },
      YTD_PERCENTAGE_REVENUE: {
        name: 'YTD % Rev',
        value: `${month}${PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.YTD.REVENUE_PERCENT}`,
      },
    }
  }
}

export const PROFIT_LOSS_MONTHLY_BASE_COLUMN_SETTINGS = (props) => {
  const { year, period, isCurrency, isMilesSection, hideNonAmountData } = props

  const createLinkPath = ({
    categoryInternalAccountNumber,
    categoryName,
    columnName,
  }) => {
    if (isTotalRow(categoryName) || columnName !== `${period}Amount`)
      return null

    return `${
      APP_PATH.FINANCIAL_PERFORMANCE.PL_CATEGORY_TRANSACTIONS
    }/${kebabCase(
      categoryName
    )}/${categoryInternalAccountNumber}?year=${year}&period=${period}`
  }

  const MONTHLY_COLUMN_DEFINITIONS = PROFIT_LOSS_MONTHLY_COLUMN(
    period,
    isMilesSection,
    hideNonAmountData
  )

  let blankCount = 0
  const columnSettings = map(MONTHLY_COLUMN_DEFINITIONS, (columnData) => {
    if (columnData.type === 'hidden') {
      return {
        header: columnData.name,
        Header: () => (
          <div style={{ visibility: 'hidden' }} title={columnData.name}>
            {columnData.name}
          </div>
        ),
        accessorKey: `_blank-${year}-${period}-${blankCount++}`, // this won't exist
        Cell: () => <div />,
        minSize: 126.4, // needs to match the Cents Per Mile columns
      }
    }

    return {
      header: columnData.name,
      accessorKey: columnData.value,
      Cell: ({ cell, row }) => (
        <CellFormatter
          linkPath={createLinkPath({
            categoryInternalAccountNumber: row.original.internalAccountNumber,
            categoryName: row.original.name,
            columnName: columnData.value,
          })}
          rawValue={cell.getValue()}
          isUnits={
            columnData.name.includes('Cents') || isMilesToBreakevenRow(row)
          }
          isPercentage={columnData.name.includes('%')}
          isCurrency={isCurrency}
          hasPositiveBenchmark={row.original[columnData.benchmarkKey]}
        />
      ),
    }
  })
  return columnSettings
}

export const PROFIT_LOSS_PERIOD_COLUMN = (props, periodProps) => {
  const { year, isCurrency } = props
  const createLinkPath = (categoryInternalAccountNumber, categoryName) => {
    const linkableCell = !isTotalRow(categoryName)
    if (!linkableCell) return null
    return `${
      APP_PATH.FINANCIAL_PERFORMANCE.PL_CATEGORY_TRANSACTIONS
    }/${kebabCase(
      categoryName
    )}/${categoryInternalAccountNumber}?year=${year}&period=${
      periodProps.periodSelectorValue
    }`
  }

  return {
    header: periodProps.name,
    accessorKey: periodProps.value,
    Cell: ({ cell, row }) => {
      const rowName = row.original.name.toLowerCase()
      const isUnits = rowName.includes('miles') && !rowName.includes('tax')
      return (
        <CellFormatter
          linkPath={createLinkPath(
            row.original.internalAccountNumber,
            row.original.name
          )}
          rawValue={cell.getValue()}
          isUnits={isUnits}
          isCurrency={isCurrency}
          hasPositiveBenchmark={row.original[periodProps.benchmarkKey]}
        />
      )
    },
  }
}

export const PROFIT_LOSS_NESTING_SUBROW_YTD_NAME_SETTINGS = (props) => {
  return [
    {
      header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.NAME.name,
      accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.NAME.value,
      minSize: 125, //min size enforced during resizing
      size: 180, //medium column
      columns: [
        {
          header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.NAME.name,
          accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.NAME.value,
          minSize: 125, //min size enforced during resizing
          size: 180, //medium column
          Cell: ({ cell, row }) => <TableRowCell {...{ cell, row }} />,
        },
        ...PROFIT_LOSS_PERIODS.map((periodProps) =>
          PROFIT_LOSS_PERIOD_COLUMN(props, periodProps)
        ),
      ],
    },
  ]
}

export const PROFIT_LOSS_NESTING_SUBROW_MONTHLY_NAME_SETTINGS = (props) => {
  const { month, isMilesSection, hideNonAmountData } = props

  const MONTHLY_COLUMN_DEFINITIONS = PROFIT_LOSS_MONTHLY_COLUMN(
    month,
    isMilesSection,
    hideNonAmountData
  )

  return {
    header: MONTHLY_COLUMN_DEFINITIONS.NAME.name,
    accessorKey: MONTHLY_COLUMN_DEFINITIONS.NAME.value,
    minSize: 125, //min size enforced during resizing
    size: 180, //medium column
    columns: [
      {
        header: MONTHLY_COLUMN_DEFINITIONS.NAME.name,
        accessorKey: MONTHLY_COLUMN_DEFINITIONS.NAME.value,
        minSize: 125, //min size enforced during resizing
        size: 180, //medium column
        Cell: ({ cell, row }) => <TableRowCell {...{ cell, row }} />,
      },
    ],
  }
}

export const PROFIT_LOSS_SINGLE_MONTH_SETTINGS = (props) => {
  const {
    period,
    year,
    accessorKey,
    isCurrency,
    isPercentage,
    isUnits,
    isAmountValue,
  } = props

  const createLinkPath = (categoryInternalAccountNumber, categoryName) => {
    if (isTotalRow(categoryName) || !isAmountValue) return null

    return `${
      APP_PATH.FINANCIAL_PERFORMANCE.PL_CATEGORY_TRANSACTIONS
    }/${kebabCase(
      categoryName
    )}/${categoryInternalAccountNumber}?year=${year}&period=${period}`
  }

  return [
    {
      header: '',
      accessorKey: 'name',
      columns: [
        {
          header: '',
          accessorKey: 'name',
          Cell: ({ cell, row }) => <TableRowCell {...{ cell, row }} />,
        },
      ],
    },
    {
      header: '',
      accessorKey,
      Cell: ({ cell, row }) => (
        <CellFormatter
          linkPath={createLinkPath(
            row.original.internalAccountNumber,
            row.original.name
          )}
          rawValue={cell.getValue()}
          isCurrency={isCurrency}
          isPercentage={isPercentage}
          isUnits={isUnits || isMilesToBreakevenRow(row)}
          hasPositiveBenchmark={
            isAmountValue ? row.original[`${period}BenchmarkStatus`] : undefined
          }
        />
      ),
    },
  ]
}

export const PROFIT_LOSS_MONTHLY_NESTING_SETTINGS = (props) => [
  PROFIT_LOSS_NESTING_SUBROW_MONTHLY_NAME_SETTINGS(props),
  ...PROFIT_LOSS_MONTHLY_BASE_COLUMN_SETTINGS(props),
]

export const FINANCIAL_PERFORMANCE_TOTAL_ROWS = [
  'total',
  'contribution margin',
  'net income before tax',
  'to breakeven',
  'estimated cash',
  'tax estimate',
  'est tax',
]

export const FINANCIAL_PERFORMANCE_MASTER_TOTAL_ROW = 'net income from business'

export const TUTORIAL_VIDEOS = [
  {
    title: 'Intro Page & Checklist',
    url: process.env.TUTORIAL_URL_INTRO_PAGE,
  },
  {
    title: 'How ATBS Works: Getting Started',
    url: process.env.TUTORIAL_URL_GETTING_STARTED,
  },
  {
    title: 'How ATBS Works: Quarterly Tax Estimates',
    url: process.env.TUTORIAL_URL_QUARTERLY_ESTIMATES,
  },
  {
    title: 'How ATBS Works: Profit Plan',
    url: process.env.TUTORIAL_URL_PROFIT_PLAN,
  },
]

export const PER_DIEM_ALLOCATION = {
  FULL_DAY: 'fullDay',
  PARTIAL_DAY: 'partialDay',
  CLEAR: 'clear',
}

export const DOCUMENT_SCALE = {
  START: {
    DESKTOP: 100,
    MOBILE: 50,
  },
  MAX: 300,
  MIN: 10,
  CHANGE_AMOUNT: 10,
}

export const BALANCE_SHEET_CATEGORY = {
  LIABILITIES: {
    name: 'Total Liabilities',
    displayName: 'Liabilities',
    color: 'var(--ion-color-danger-tint)',
  },
  ASSETS: {
    name: 'Total Assets',
    displayName: 'Assets',
    color: 'var(--ion-color-success)',
  },
}

export const REVENUE_CHART_CATEGORY = {
  MILEAGE_PERCENTAGE: {
    sfid: 'a0O700000019fUrEAI',
    displayName: 'Mileage / Percentage',
    color: '#92A9FF',
  },
  FUEL_SURCHARGE: {
    sfid: 'a0O700000019fUpEAI',
    displayName: 'Fuel Surcharge',
    color: '#2C55E8',
  },
  ACCESSORIAL_OTHER: {
    sfid: 'a0O700000019fUqEAI',
    displayName: 'Accessorial / Other',
    color: '#002094',
  },
}

export const MILES_CHART_CATEGORY = {
  LOADED_MILES: {
    sfid: 'a0O700000019fUoEAI',
    displayName: 'Loaded Miles',
    color: 'var(--ion-color-success)',
  },
  EMPTY_MILES: {
    sfid: 'a0O700000019fUnEAI',
    displayName: 'Empty Miles',
    color: 'var(--ion-color-danger-tint)',
  },
  PERCENT_EMPTY: {
    displayName: '% Empty',
    color: 'var(--ion-color-base)',
  },
}

export const MILES_BAR_GRAPH_CATEGORIES = [
  MILES_CHART_CATEGORY.LOADED_MILES,
  MILES_CHART_CATEGORY.EMPTY_MILES,
]

export const TOTAL_MILES_CATEGORY = 'Total Miles'
export const TOTAL_REVENUE_CATEGORY = 'Total Revenue'

export const EXPENSES_CHART_COLORS = [
  '#081953',
  '#002590',
  '#003CEB',
  '#5B7FEA',
  '#99AEEB',
  '#D8E1FA',
  '#896202',
  '#BF8A0B',
  '#E7B12F',
  '#EDCB78',
  '#ECD6A0',
  '#F0E5CB',
  '#000000',
  '#3A3939',
  '#727272',
  '#BABABA',
  '#E3E3E3',
  '#EDEDED',
]

export const TOTAL_EXPENSES_CATEGORY = {
  TOTAL_VARIABLE_EXPENSES: 'Total Variable Expenses',
  TOTAL_FIXED_EXPENSES: 'Total Fixed Expenses',
  TOTAL_EXPENSES: 'Total Expenses',
}

export const BS_CHART_TOTAL_CATEGORY = {
  TOTAL_ASSETS: 'Total Assets',
  TOTAL_LIABILITIES: 'Total Liabilities',
  TOTAL_OWNERS_EQUITY: 'Total Owners Equity',
}

export const DATA_VIEW_TYPE = {
  TABLE: 'table',
  CHART: 'chart',
}

export const EXPENSES_TABLE_TYPES = {
  VARIABLE: 'variable',
  FIXED: 'fixed',
  LIABILITIES: 'liabilities',
}

export const EXPENSES_DONUT_CHART_TYPES = {
  VARIABLE: 'variable',
  FIXED: 'fixed',
}

export const MILES_CATEGORY_ACCOUNT_NUMBERS = ['8', '9']

export const NET_INCOME_BEFORE_TAX = 'Net Income Before Tax'

export const NET_INCOME_NAMES = [
  'Total Revenue',
  'Total Expenses',
  NET_INCOME_BEFORE_TAX,
]

export const PROFIT_LINE_ITEMS = [
  NET_INCOME_BEFORE_TAX,
  BREAKEVEN_POINT_CATEGORY.MILES,
  BREAKEVEN_POINT_CATEGORY.REVENUE,
  'Estimated Cash Flow',
  'Est Tax',
]

export const PROFIT_LOSS_MOBILE_SEGMENT = {
  MILES: {
    name: 'miles',
    tables: [
      {
        label: 'Amount',
        accessorKeySubstring: PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.AMOUNT,
        isCurrency: false,
        isPercentage: false,
        isUnits: true,
        doNotApplyRowTotalsStyle: false,
      },
      {
        label: '% Total',
        accessorKeySubstring: PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.PERCENT_OF_TOTAL,
        isCurrency: false,
        isPercentage: true,
        isUnits: false,
        doNotApplyRowTotalsStyle: false,
      },
    ],
  },
  REVENUE: {
    name: 'revenue',
    tables: [
      {
        label: 'Amount',
        accessorKeySubstring: PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.AMOUNT,
        isCurrency: true,
        isPercentage: false,
        isUnits: false,
        doNotApplyRowTotalsStyle: false,
      },
      {
        label: 'Cents Per Mile',
        accessorKeySubstring: PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.CENTS_PER_MILE,
        isCurrency: false,
        isPercentage: false,
        isUnits: true,
        doNotApplyRowTotalsStyle: false,
      },
      {
        label: '% Rev',
        accessorKeySubstring: PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.REVENUE_PERCENT,
        isCurrency: false,
        isPercentage: true,
        isUnits: false,
        doNotApplyRowTotalsStyle: false,
      },
    ],
  },
  EXPENSES: {
    name: 'expenses',
    tables: [
      {
        label: 'Amount',
        accessorKeySubstring: PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.AMOUNT,
        isCurrency: true,
        isPercentage: false,
        isUnits: false,
        doNotApplyRowTotalsStyle: false,
      },
      {
        label: 'Cents Per Mile',
        accessorKeySubstring: PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.CENTS_PER_MILE,
        isCurrency: false,
        isPercentage: false,
        isUnits: true,
        doNotApplyRowTotalsStyle: false,
      },
      {
        label: '% Rev',
        accessorKeySubstring: PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.REVENUE_PERCENT,
        isCurrency: false,
        isPercentage: true,
        isUnits: false,
        doNotApplyRowTotalsStyle: false,
      },
    ],
  },
  PROFIT: {
    name: 'profit',
    tables: [
      {
        label: 'Amount',
        accessorKeySubstring: PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.AMOUNT,
        isCurrency: true,
        isPercentage: false,
        isUnits: false,
        doNotApplyRowTotalsStyle: true,
      },
      {
        label: 'Cents Per Mile',
        accessorKeySubstring: PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.CENTS_PER_MILE,
        isCurrency: false,
        isPercentage: false,
        isUnits: true,
        doNotApplyRowTotalsStyle: true,
      },
      {
        label: '% Rev',
        accessorKeySubstring: PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.REVENUE_PERCENT,
        isCurrency: false,
        isPercentage: true,
        isUnits: false,
        doNotApplyRowTotalsStyle: true,
      },
    ],
  },
}

export const MIME_TYPE = {
  PDF: 'application/pdf',
  CSV: 'text/csv',
}

export const UPLOAD_MODAL_BREAKPOINT = {
  SHORT: 0.25,
  TALL: 0.55,
  FULL: 0.9,
}

export const UPLOAD_PAGE_TYPE = {
  SINGLE: {
    display: 'Single Page',
    value: 'single',
  },
  MULTIPLE: {
    display: 'Multiple Pages',
    value: 'multiple',
  },
}

export const APP_STORE_LINK = {
  APPLE: 'https://apps.apple.com/us/app/atbs/id1495212280',
  GOOGLE: 'https://play.google.com/store/apps/details?id=com.atbs.mobile',
}

export const minimumTripDate = '2023-01-01T00:00:00' // Prevent users from submitting trips prior to year 2023 since that's the oldest year w/ a mileage deduction rate synced in our db

export const OPEN_MILEAGE_TRACKER_INFO_MODAL = 'open-mileage-tracker-info-modal'
export const OPEN_PER_DIEM_INFO_MODAL = 'open-per-diem-info-modal'

// List of known errors from third-party libraries that we can ignore
export const PLUGIN_ERROR_MESSAGE = {
  NO_PROCESSING_NEEDED: 'No processing needed',
  PICKFILES_CANCELED: 'pickFiles canceled', // Occurs when the user abandons the "Upload Files" flow
}

export const HUB_MESSAGES_CATEGORY = {
  INBOX: 'inbox',
  ARCHIVED: 'archived',
}

export const HUB_MESSAGE_TYPE = {
  DOCUMENT_UPLOAD: 'document_upload',
  READ_ONLY: 'read_only',
  REPLY: 'reply',
}
