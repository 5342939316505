import { handleActions } from 'redux-actions'
import { selectorForSlice, setState, unsetState } from 'lp-redux-utils'
import { setOnSuccess, handleSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import * as actions from './actions'
import { createSelector } from 'reselect'
import { HUB_MESSAGES_CATEGORY } from 'config'

const reducerKey = 'hub-messages'
const slice = 'root.hub-messages'

const initialState = {
  rawHubMessages: [],
  hubMessagesFilter: HUB_MESSAGES_CATEGORY.INBOX,
}

const setHubMessagesFromSuccess = (state, { payload }) => {
  const updatedHubMessage = payload.data

  if (!updatedHubMessage) return state

  const updatedRawHubMessage = {
    id: updatedHubMessage.id,
    type: 'hub_message',
    attributes: updatedHubMessage,
  }
  const doesRawHubMessagesContainUpdatedMessage = state.rawHubMessages.some(
    (rawMessage) => rawMessage.attributes.id === updatedRawHubMessage.id
  )
  const updatedRawHubMessages = doesRawHubMessagesContainUpdatedMessage
    ? state.rawHubMessages.map((rawMessage) =>
        rawMessage.attributes.id === updatedRawHubMessage.id
          ? updatedRawHubMessage
          : rawMessage
      )
    : [...state.rawHubMessages, updatedRawHubMessage]

  return {
    ...state,
    hubMessage: updatedHubMessage,
    rawHubMessages: updatedRawHubMessages,
  }
}

const reducer = handleActions(
  {
    [apiActions.fetchHubMessages]: setOnSuccess('rawHubMessages'),
    [apiActions.fetchHubMessage]: handleSuccess(setHubMessagesFromSuccess),
    [actions.setHubMessagesFilter]: setState('hubMessagesFilter'),
    [actions.clearHubMessage]: unsetState('hubMessage'),
    [apiActions.updateHubMessage]: handleSuccess(setHubMessagesFromSuccess),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  rawHubMessages: select('rawHubMessages'),
  hubMessage: select('hubMessage'),
  hubMessagesFilter: select('hubMessagesFilter'),
}

// Computed

selectors.hubMessages = createSelector(
  [selectors.rawHubMessages],
  (rawHubMessages) => {
    if (!rawHubMessages) return []

    return rawHubMessages.map(({ attributes }) => attributes)
  }
)

selectors.activeHubMessages = createSelector(
  [selectors.hubMessages],
  (hubMessages) => {
    return hubMessages.filter((message) => !message.archived)
  }
)

selectors.hasActiveHubMessages = createSelector(
  [selectors.activeHubMessages],
  (activeHubMessages) => activeHubMessages.length > 0
)

selectors.activeHubMessagesCount = createSelector(
  [selectors.activeHubMessages],
  (activeHubMessages) => activeHubMessages.length
)

selectors.archivedHubMessages = createSelector(
  [selectors.hubMessages],
  (hubMessages) => {
    return hubMessages.filter((message) => message.archived)
  }
)

selectors.filteredHubMessages = createSelector(
  [
    selectors.activeHubMessages,
    selectors.archivedHubMessages,
    selectors.hubMessagesFilter,
  ],
  (activeHubMessages, archivedHubMessages, hubMessagesFilter) => {
    if (!activeHubMessages || !archivedHubMessages || !hubMessagesFilter)
      return []

    return hubMessagesFilter === HUB_MESSAGES_CATEGORY.INBOX
      ? activeHubMessages
      : archivedHubMessages
  }
)

export { reducer, selectors, reducerKey }
