import React from 'react'
import exact from 'prop-types-exact'
import * as Types from 'types'
import { IonCard, IonCardHeader, IonCardContent, IonImg } from '@ionic/react'
import { displayHubMessageDate } from 'utils'
import docImg from 'images/DOC.svg'
import userImg from 'images/user.svg'
import { DocumentStatusBadge } from 'components'

const propTypes = {
  documentStatus: Types.hubMessageDocument.isRequired,
}

const defaultProps = {}

function HubMessageDocument({ documentStatus }) {
  const dateDisplay = displayHubMessageDate(documentStatus.createdAt)
  return (
    <IonCard>
      <IonCardHeader className="hub-message-ion-card-header hub-message-document-header">
        <div className="hub-message-show-header">
          <IonImg src={userImg} className="user-img" alt="" />
          <div>{dateDisplay}</div>
        </div>
      </IonCardHeader>
      <IonCardContent className="hub-message-show-content hub-message-document-content">
        <div className="hub-message-document-title">
          <IonImg src={docImg} className="doc-img" alt="" />
          <div>{documentStatus.title}</div>
        </div>
        <DocumentStatusBadge status={documentStatus.status} />
      </IonCardContent>
    </IonCard>
  )
}

HubMessageDocument.propTypes = exact(propTypes)
HubMessageDocument.defaultProps = defaultProps

export default React.memo(HubMessageDocument)
