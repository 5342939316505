import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { map, startCase } from 'lodash'
import { HUB_MESSAGES_CATEGORY } from 'config'
import { FilteredContentTable } from 'components'
import NoHubMessageContent from '../components/NoHubMessageContent'
import HubMessageLineItem from '../components/HubMessageLineItem'

const propTypes = {
  fetchHubMessages: PropTypes.func.isRequired,
  hubMessagesFilter: PropTypes.string.isRequired,
  setHubMessagesFilter: PropTypes.func.isRequired,
  filteredHubMessages: PropTypes.arrayOf(Types.hubMessage),
  activeHubMessagesCount: PropTypes.number.isRequired,
}

const defaultProps = {
  filteredHubMessages: null,
}

function AllHubMessages({
  fetchHubMessages,
  hubMessagesFilter,
  setHubMessagesFilter,
  filteredHubMessages,
  activeHubMessagesCount,
}) {
  useEffect(() => {
    fetchHubMessages()
  }, [fetchHubMessages])

  const categories = map(HUB_MESSAGES_CATEGORY, (category) => {
    const categoryValue = category
    const categoryLabel =
      category == HUB_MESSAGES_CATEGORY.INBOX
        ? `${startCase(category)} (${activeHubMessagesCount})`
        : startCase(category)
    return { categoryValue, categoryLabel }
  })
  return (
    <FilteredContentTable
      dataFilter={hubMessagesFilter}
      setDataFilter={setHubMessagesFilter}
      filteredData={filteredHubMessages}
      categories={categories}
      noFilteredTableContent={NoHubMessageContent}
      itemComponent={HubMessageLineItem}
      getItemProps={(item) => ({ hubMessage: item })}
    />
  )
}

AllHubMessages.propTypes = exact(propTypes)
AllHubMessages.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    filteredHubMessages: selectors.filteredHubMessages(state),
    hubMessagesFilter: selectors.hubMessagesFilter(state),
    activeHubMessagesCount: selectors.activeHubMessagesCount(state),
  }
}

const mapDispatchToProps = {
  fetchHubMessages: apiActions.fetchHubMessages,
  setHubMessagesFilter: actions.setHubMessagesFilter,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AllHubMessages
)
