import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import NoDataContent from './NoDataContent'
import { documentTextOutline } from 'ionicons/icons'

const propTypes = {
  children: PropTypes.node,
  theme: PropTypes.string,
}

const defaultProps = {
  children: <span>No Documents</span>,
  theme: 'light',
}

function NoDocumentContent({ children, theme }) {
  return (
    <NoDataContent theme={theme} icon={documentTextOutline}>
      {children}
    </NoDataContent>
  )
}

NoDocumentContent.propTypes = exact(propTypes)
NoDocumentContent.defaultProps = defaultProps

export default React.memo(NoDocumentContent)
