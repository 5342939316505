import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { selectors } from '../reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { map, startCase } from 'lodash'
import {
  DocumentStatusLineItem,
  NoDocumentContent,
  FilteredContentTable,
} from 'components'
import { DOCUMENT_CATEGORY } from 'config'

const propTypes = {
  fetchDocuments: PropTypes.func.isRequired,
  documentFilter: PropTypes.string.isRequired,
  setDocumentFilter: PropTypes.func.isRequired,
  filteredDocs: PropTypes.arrayOf(Types.doc),
}

const defaultProps = {
  filteredDocs: null,
}

function AllDocuments({
  fetchDocuments,
  documentFilter,
  setDocumentFilter,
  filteredDocs,
}) {
  const [error, setError] = useState()

  useEffect(() => {
    const requestDocuments = async () => {
      try {
        await fetchDocuments()
      } catch (errorMessage) {
        setError(`There was an issue fetching your documents: ${errorMessage}`)
      }
    }

    requestDocuments()
  }, [fetchDocuments])

  if (error) {
    return <div className="centered-content">{error}</div>
  }

  const categories = map(DOCUMENT_CATEGORY, (category) => {
    const categoryValue = category
    const categoryLabel = startCase(category)
    return { categoryValue, categoryLabel }
  })

  return (
    <FilteredContentTable
      dataFilter={documentFilter}
      setDataFilter={setDocumentFilter}
      filteredData={filteredDocs}
      categories={categories}
      noFilteredTableContent={NoDocumentContent}
      itemComponent={DocumentStatusLineItem}
      getItemProps={(item) => ({ doc: item })}
    />
  )
}

AllDocuments.propTypes = exact(propTypes)
AllDocuments.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    documentFilter: selectors.documentFilter(state),
    filteredDocs: selectors.filteredDocs(state),
  }
}

const mapDispatchToProps = {
  fetchDocuments: apiActions.fetchDocuments,
  setDocumentFilter: actions.setDocumentFilter,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AllDocuments
)
