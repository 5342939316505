import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { useParams } from 'react-router-dom'
import { selectors } from '../reducer'
import { selectors as mainSelectors } from '../../reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import * as mainActions from '../../actions'
import * as documentActions from '../../documents/actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { LoadingSpinner, UploadFAB, UploadModal } from 'components'
import {
  HubMessageDocument,
  HubMessageReply,
  HubMessageArchiveButton,
  HubMessageArchivalConfirmationModal,
} from '../components'
import { HUB_MESSAGE_TYPE, APP_PATH } from 'config'
import { HubMessageReplyForm } from '../forms'
import {
  displayHubMessageDate,
  shouldDisplayHubMessageActionNeeded,
} from 'utils'
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonLabel,
  useIonViewWillEnter,
  useIonViewDidLeave,
  useIonRouter,
} from '@ionic/react'

const propTypes = {
  fetchHubMessage: PropTypes.func.isRequired,
  clearHubMessage: PropTypes.func.isRequired,
  isUploadModalOpen: PropTypes.bool.isRequired,
  setIsUploadModalOpen: PropTypes.func.isRequired,
  addFileToDocument: PropTypes.func.isRequired,
  setUploadedPhoto: PropTypes.func.isRequired,
  updateHubMessage: PropTypes.func.isRequired,
  hubMessage: Types.hubMessage,
}

const defaultProps = {
  hubMessage: null,
}

function HubMessageShow({
  fetchHubMessage,
  clearHubMessage,
  isUploadModalOpen,
  addFileToDocument,
  setUploadedPhoto,
  setIsUploadModalOpen,
  updateHubMessage,
  hubMessage,
}) {
  const { id } = useParams()
  const [hubMessageError, setHubMessageError] = useState()
  const [showReplyForm, setShowReplyForm] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const router = useIonRouter()

  useIonViewWillEnter(() => {
    const getHubMessage = async () => {
      try {
        await fetchHubMessage(id)
      } catch (errorMessage) {
        setHubMessageError('There was an issue loading the hub message')
      }
    }

    if (id) {
      getHubMessage()
    }
  }, [id, fetchHubMessage])

  useIonViewDidLeave(() => {
    clearHubMessage()
  }, [clearHubMessage])
  if (hubMessageError) {
    return <div className="centered-content">{hubMessageError} </div>
  }

  if (!hubMessage) {
    return (
      <LoadingSpinner>
        <p>Hub Message Loading...</p>
      </LoadingSpinner>
    )
  }
  const hubMessageDateDisplay = displayHubMessageDate(hubMessage.createdAt)
  const showActionNeeded = shouldDisplayHubMessageActionNeeded(hubMessage)
  const showUploadDocuments =
    hubMessage.messageType == HUB_MESSAGE_TYPE.DOCUMENT_UPLOAD &&
    !hubMessage.archived
  const showArchiveButton = !hubMessage.archived && !showReplyForm
  const showReplyButton =
    hubMessage.messageType == HUB_MESSAGE_TYPE.REPLY &&
    !hubMessage.archived &&
    !showReplyForm
  const documentStatus = hubMessage.documentStatus
  const showDocument =
    hubMessage.messageType == HUB_MESSAGE_TYPE.DOCUMENT_UPLOAD && documentStatus
  const showReply = HUB_MESSAGE_TYPE.REPLY && hubMessage.response
  const showDividingLine = showReplyForm || showDocument || showReply

  const handleArchive = async () => {
    try {
      await updateHubMessage({ archived: true }, hubMessage.id)
      router.push(APP_PATH.HUB_MESSAGES.ROOT, 'back', 'pop')
    } catch {
      setHubMessageError(
        'There was an issue archiving the hub message. Please try again'
      )
    }
  }

  const handleArchiveButtonInitialClick = () => {
    if (showActionNeeded) {
      // showActionNeeded is a variable that corresponds to
      // a message being either a document upload or a reply message
      // For these kinds of messages, we show a confirmation modal when someone clicks "Archive"
      return setIsModalOpen(true)
    } else {
      return handleArchive()
    }
  }

  return (
    <>
      <IonCard>
        <IonCardHeader className="hub-message-ion-card-header">
          {/* We wrap all of IonCardHeader's content in a div here, because for some reason, IOS views will reorder the content of IonCardHeader divs if there are multiple child divs. */}
          <div>
            <div className="hub-message-show-header">
              <div className="atbs-circle">ATBS</div>
              <div>{hubMessageDateDisplay}</div>
            </div>
            {showActionNeeded && (
              <div className="message-action-needed-badge hub-message-show-action-needed-badge">
                Action Needed
              </div>
            )}
            <IonCardTitle className="hub-message-show-title">
              {hubMessage.subject}
            </IonCardTitle>
          </div>
        </IonCardHeader>
        <IonCardContent className="hub-message-show-content">
          {hubMessage.body}
        </IonCardContent>
      </IonCard>
      {showUploadDocuments && (
        <>
          <UploadFAB
            setIsOpen={setIsUploadModalOpen}
            useHubMessageStyle={true}
          ></UploadFAB>
          <UploadModal
            isOpen={isUploadModalOpen}
            setIsOpen={setIsUploadModalOpen}
            addFileToDocument={addFileToDocument}
            setUploadedPhoto={setUploadedPhoto}
            hubMessageId={hubMessage.id}
          />
        </>
      )}
      {showReplyButton && (
        <IonButton
          className="action-button hub-message-button hub-message-non-archive-button"
          onClick={() => {
            setShowReplyForm(true)
          }}
          color="secondary"
        >
          <IonLabel>Reply</IonLabel>
        </IonButton>
      )}
      {showDividingLine && <hr></hr>}
      {showReplyForm && (
        <HubMessageReplyForm
          initialValues={{ reply: '' }}
          handleSubmit={async (values) => {
            try {
              await updateHubMessage({ response: values.reply }, hubMessage.id)
              setShowReplyForm(false)
            } catch {
              setHubMessageError(
                'There was an issue saving you reply. Please try again'
              )
            }
          }}
          onCancel={() => {
            return setShowReplyForm(false)
          }}
        />
      )}
      {showArchiveButton && (
        <HubMessageArchiveButton
          handleClick={handleArchiveButtonInitialClick}
        />
      )}
      <HubMessageArchivalConfirmationModal
        handleArchive={handleArchive}
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
      {showDocument && <HubMessageDocument documentStatus={documentStatus} />}
      {showReply && (
        <HubMessageReply
          response={hubMessage.response}
          responseSentAt={hubMessage.responseSentAt}
        />
      )}
    </>
  )
}

HubMessageShow.propTypes = exact(propTypes)
HubMessageShow.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    hubMessage: selectors.hubMessage(state),
    isUploadModalOpen: mainSelectors.isUploadModalOpen(state),
  }
}

const mapDispatchToProps = {
  fetchHubMessage: apiActions.fetchHubMessage,
  clearHubMessage: actions.clearHubMessage,
  addFileToDocument: documentActions.addFileToDocument,
  setUploadedPhoto: documentActions.setUploadedPhoto,
  setIsUploadModalOpen: mainActions.setIsUploadModalOpen,
  updateHubMessage: apiActions.updateHubMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  HubMessageShow
)
