import { combineReducers } from 'redux'
import { reducer as homeReducer, reducerKey as homeReducerKey } from './home'
import { reducer as taxReducer, reducerKey as taxReducerKey } from './tax'
import {
  reducer as financialPerformanceReducer,
  reducerKey as financialPerformanceReducerKey,
} from './financial-performance'
import {
  reducer as perDiemReducer,
  reducerKey as perDiemReducerKey,
} from './per-diem'
import {
  reducer as documentsReducer,
  reducerKey as documentsReducerKey,
} from './documents'
import {
  reducer as mileageTrackerReducer,
  reducerKey as mileageTrackerReducerKey,
} from './mileage-tracker'
import {
  reducer as hubMessagesReducer,
  reducerKey as hubMessagesReducerKey,
} from './hub-messages'
import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState, setState } from 'lp-redux-utils'
import { setOnSuccess, handleSuccess } from 'lp-redux-api'
import * as globalActions from './actions'
import * as apiActions from 'api-actions'

const reducerKey = 'root'
const slice = 'root.global'

const initialState = {
  isUploadModalOpen: false,
}

const globalReducer = handleActions(
  {
    [apiActions.fetchUserProfile]: setOnSuccess('currentUser'),
    [apiActions.logOut]: handleSuccess((state) => {
      return unsetState('currentUser')(state)
    }),
    [globalActions.clearCurrentUser]: unsetState('currentUser'),
    [globalActions.setIsUploadModalOpen]: setState('isUploadModalOpen'),
  },
  initialState
)

const reducer = combineReducers({
  [homeReducerKey]: homeReducer,
  [taxReducerKey]: taxReducer,
  [documentsReducerKey]: documentsReducer,
  [financialPerformanceReducerKey]: financialPerformanceReducer,
  [perDiemReducerKey]: perDiemReducer,
  [mileageTrackerReducerKey]: mileageTrackerReducer,
  [hubMessagesReducerKey]: hubMessagesReducer,
  global: globalReducer,
})

const select = selectorForSlice(slice)

const selectors = {
  currentUser: select('currentUser'),
  isUploadModalOpen: select('isUploadModalOpen'),
}

export { reducer, selectors, reducerKey }
