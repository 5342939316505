import React from 'react'
import {
  IonContent,
  IonButton,
  IonModal,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonRow,
  IonHeader,
  IonGrid,
} from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleArchive: PropTypes.func.isRequired,
}

const defaultProps = {}

function HubMessageArchivalConfirmationModal({
  closeModal,
  isModalOpen,
  handleArchive,
}) {
  return (
    <IonModal
      className="custom-modal"
      isOpen={isModalOpen}
      onDidDismiss={closeModal}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton
              color="light"
              onClick={closeModal}
              aria-label="Cancel archiving a message"
            >
              <IonIcon icon={closeOutline} slot="icon-only" color="dark" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonGrid>
        <IonContent>
          <h3 className="archival-confirmation-bold-text">
            Are you sure you want to archive this message?
          </h3>
          <p className="archival-confirmation-main-text">
            This message will auto archive after you reply or upload the
            document. Please archive this only if you've already sent the
            information.
          </p>
          <IonRow className="ion-justify-content-center archival-confirmation-row">
            <IonButton
              color="secondary"
              expand="block"
              onClick={() => {
                handleArchive()
                closeModal()
              }}
            >
              Yes, archive
            </IonButton>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton fill="outline" expand="block" onClick={closeModal}>
              Cancel
            </IonButton>
          </IonRow>
        </IonContent>
      </IonGrid>
    </IonModal>
  )
}

HubMessageArchivalConfirmationModal.propTypes = exact(propTypes)
HubMessageArchivalConfirmationModal.defaultProps = defaultProps

export default React.memo(HubMessageArchivalConfirmationModal)
