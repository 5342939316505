import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonIcon } from '@ionic/react'

const propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

const defaultProps = {}

function NoDataContent({ children, theme, icon }) {
  return (
    <div className={`centered-content no-data-content ${theme}`}>
      <div className="ion-padding">
        <div className="icon-circle-bg no-objects">
          <IonIcon icon={icon} aria-hidden="true" size="large" />
        </div>
        {children}
      </div>
    </div>
  )
}

NoDataContent.propTypes = exact(propTypes)
NoDataContent.defaultProps = defaultProps

export default React.memo(NoDataContent)
