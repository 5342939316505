import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import NoDataContent from 'components/NoDataContent'
import noNewHubMessagesIcon from 'images/MESSAGES-NO-NEW.svg'

const propTypes = {
  children: PropTypes.node,
  theme: PropTypes.string,
}

const defaultProps = {
  children: <span>No Hub Messages</span>,
  theme: 'light',
}

function NoHubMessageContent({ children, theme }) {
  return (
    <NoDataContent theme={theme} icon={noNewHubMessagesIcon}>
      {children}
    </NoDataContent>
  )
}

NoHubMessageContent.propTypes = exact(propTypes)
NoHubMessageContent.defaultProps = defaultProps

export default React.memo(NoHubMessageContent)
