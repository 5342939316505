import React from 'react'
import exact from 'prop-types-exact'
import { IonItem } from '@ionic/react'
import { DocumentStatusBadge } from 'components'
import { format } from 'date-fns'
import * as Types from 'types'
import { APP_PATH } from 'config'

const propTypes = {
  doc: Types.doc.isRequired,
}

const defaultProps = {}

function DocumentStatusLineItem({ doc }) {
  return (
    <IonItem
      lines="full"
      detail="false"
      routerLink={`${APP_PATH.DOCUMENTS.VIEW}/${doc.guid}`}
      button
    >
      <div className="table-content-line-item-wrapper ion-justify-content-between">
        <div className="table-content-text-block">
          <div className="table-content-line-item-title">{doc.title}</div>
          <div className="table-content-line-item-subtitle">
            {format(new Date(doc.createdAt), 'MM-dd-yyyy')}
          </div>
        </div>
        <DocumentStatusBadge status={doc.status} />
      </div>
    </IonItem>
  )
}

DocumentStatusLineItem.propTypes = exact(propTypes)
DocumentStatusLineItem.defaultProps = defaultProps

export default React.memo(DocumentStatusLineItem)
