import { configureApi, http } from 'lp-requests'
import { middleware as configureMiddleware } from 'lp-redux-api'
import { get, isEmpty, isError } from 'lodash'
import { getSessionToken } from 'auth'
import { APP_PATH, UNAUTHENTICATED_PATHS } from 'config'

// Configure lp-redux-api middleware

export const getAuthorizationToken = () => `Bearer ${getSessionToken()}`

// This function will be passed the request options before every request.
// You can use it to set additional options or override existing ones.
function before({ skipAuthorization = false }) {
  if (skipAuthorization) return {}

  return {
    headers: {
      Authorization: getAuthorizationToken(),
    },
  }
}

// Any transformations of successful responses can go here.
// By default, we pull out the value nested at `data.attributes`.
function onSuccess(res) {
  return get(res, 'data.attributes', res.data)
}

// Any transformations of failed responses can go here.
function onFailure(res) {
  // An HttpError (thrown by lp-requests, is not considered empty, but a standard Error is (e.g., TypeError: failed to fetch)
  if (isEmpty(res))
    return isError(res)
      ? 'Something went wrong. Please check your internet connection and try again.'
      : res

  if ([502, 503].includes(res.status)) {
    // External API Error
    return 'Please try again in 5-10 minutes'
  }

  const isUnauthorized = res.status === 401
  const isAttemptingToSignIn = window.location.pathname === APP_PATH.SIGN_IN

  // Skip and return the error if the user is trying to sign in
  if (isUnauthorized && !isAttemptingToSignIn) {
    // Deep links initially go through the root path for some reason;
    // Adding this check prevents unauthenticated deep links (e.g., '/reset-password')
    // from redirecting to sign in after our AuthenticatedLayout tries to authenticate the user.
    if (
      window.location.pathname === APP_PATH.ROOT ||
      isUnauthenticatedPath(window.location.pathname)
    )
      return

    // Unauthorized request (could be due to timeout or never having logged in)
    window.location.assign(APP_PATH.SIGN_IN)
    return 'You have been logged out for security. Redirecting to Login.'
  }
  return res.errors?.[0]?.title || res.response?.error || res.message
}

export const config = {
  root: process.env.API_URL,
  before,
  onSuccess,
  onFailure,
  mode: 'cors',
}

export const middleware = configureMiddleware(http, config)
export const api = configureApi(config)

// private

const isUnauthenticatedPath = (pathname) =>
  UNAUTHENTICATED_PATHS.some((route) => pathname.startsWith(route))
