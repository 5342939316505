import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { IonIcon, IonText, IonButton } from '@ionic/react'
import { checkmarkCircleOutline } from 'ionicons/icons'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { APP_PATH } from 'config'

const propTypes = {
  fetchLatestDocs: PropTypes.func.isRequired,
  resetDocument: PropTypes.func.isRequired,
}

const defaultProps = {}

function SuccessSplash({ fetchLatestDocs, resetDocument }) {
  useEffect(() => {
    return () => fetchLatestDocs()
  }, [fetchLatestDocs])
  const { hubMessageId } = useParams()
  const redirectLink = hubMessageId
    ? `${APP_PATH.HUB_MESSAGES.VIEW}/${hubMessageId}`
    : APP_PATH.HOME
  return (
    <div className="splash-wrapper ion-padding">
      <div className="flex-wrapper">
        <div className="icon-circle-bg success">
          <IonIcon icon={checkmarkCircleOutline} size="large"></IonIcon>
        </div>
        <h2>On its way!</h2>
        <IonText className="ion-text-center">
          Your document is on its way to ATBS. We'll take care of the rest!
        </IonText>
      </div>
      <IonButton
        color="secondary"
        expand="block"
        routerLink={redirectLink}
        routerDirection="root"
        onClick={resetDocument}
      >
        Got it!
      </IonButton>
    </div>
  )
}

SuccessSplash.propTypes = propTypes
SuccessSplash.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  fetchLatestDocs: apiActions.fetchLatestDocs,
  resetDocument: actions.resetDocument,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SuccessSplash
)
