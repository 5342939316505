import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { selectors } from '../reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  IonButton,
  // IonReorderGroup,
  IonList,
  IonItem,
  IonLabel,
  // IonReorder,
  IonIcon,
  IonNote,
} from '@ionic/react'
import { useHistory, useParams } from 'react-router-dom'
import { trashOutline } from 'ionicons/icons'
import { isEmpty } from 'lodash'
import * as actions from '../actions'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { TextInput, SubmitButton } from 'components'
import * as Yup from 'yup'
import * as apiActions from 'api-actions'
import { formatFilesPayloadFromValues } from 'utils'
import { UPLOAD_FLOW_TYPE, APP_PATH, UPLOAD_PAGE_TYPE } from 'config'
import { useFileSystem, usePhotoGallery, useToast } from 'hooks'

const propTypes = {
  files: PropTypes.arrayOf(Types.fileForUpload).isRequired,
  method: PropTypes.string.isRequired,
  addFileToDocument: PropTypes.func.isRequired,
  setUploadedPhoto: PropTypes.func.isRequired,
  removeFileFromDocument: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  setTitle: PropTypes.func.isRequired,
  postDocument: PropTypes.func.isRequired,
}

const defaultProps = {}

function DocumentManager({
  files,
  method,
  addFileToDocument,
  setUploadedPhoto,
  removeFileFromDocument,
  title,
  setTitle,
  postDocument,
}) {
  const history = useHistory()
  const { hubMessageId } = useParams()
  const { selectDocumentFile } = useFileSystem()
  const { getDocumentPhoto } = usePhotoGallery({
    onNeedsPermission: () =>
      history.push(APP_PATH.DOCUMENTS.PERMISSIONS_NEEDED),
  })
  const { showFailureToast } = useToast()

  // function handleReorder(event) {
  //   // The `from` and `to` properties contain the index of the item
  //   // when the drag started and ended, respectively
  //   // console.log('Dragged from index', event.detail.from, 'to', event.detail.to);

  //   // Finish the reorder and position the item in the DOM based on
  //   // where the gesture ended. This method can also be called directly
  //   // by the reorder group
  //   event.detail.complete()
  // }

  const DocumentFileItem = ({ index, fileName, note, disabled }) => {
    return (
      <IonItem lines="none" color="dark">
        <IonLabel className="document-list-item">
          {fileName}
          <IonNote>{note || 'No Note'}</IonNote>
        </IonLabel>
        {/* <IonReorder slot="start"></IonReorder> */}
        <IonButton
          onClick={() => removeFileFromDocument(index)}
          fill="clear"
          slot="end"
          disabled={disabled}
          aria-label={`Remove ${fileName}`}
        >
          <IonIcon slot="icon-only" icon={trashOutline} color="light" />
        </IonButton>
      </IonItem>
    )
  }
  const handleSubmit = async (values) => {
    const payload = formatFilesPayloadFromValues(values)

    try {
      await postDocument(payload)
      history.push(
        `${APP_PATH.UPLOAD.SUCCESS}${hubMessageId ? `/${hubMessageId}` : ''}`
      )
    } catch (errorMessage) {
      showFailureToast({ message: errorMessage })
    }
  }

  const validationSchema = Yup.object().shape({
    files: Yup.array()
      .min(1, 'At least one file required')
      .required('required'),
    title: Yup.string(),
    hubMessageId: Yup.string(),
  })

  return (
    <div className="splash-wrapper-with-documents ion-padding">
      <Formik
        initialValues={{ files, title, hubMessageId }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={true}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className="document-preview-state" noValidate>
            <Field type="hidden" name="hubMessageId" />

            <div className="document-manager-wrapper">
              <TextInput
                name="title"
                label="Document Title (optional)"
                inputmode="text"
                onChange={(e) => {
                  setTitle(e.target.value)
                }}
                disabled={isSubmitting}
                color="light"
              />
              {!isEmpty(files) && (
                <IonList>
                  {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
                  {/* <IonReorderGroup
                    disabled={true}
                    onIonItemReorder={handleReorder}
                  > */}
                  {files.map((file, index) => (
                    <DocumentFileItem
                      key={index}
                      index={index}
                      fileName={file.fileName}
                      note={file.note}
                      disabled={isSubmitting}
                    />
                  ))}
                  {/* </IonReorderGroup> */}
                </IonList>
              )}
              <ErrorMessage name="files">
                {(msg) => <div className="error-message">{msg}</div>}
              </ErrorMessage>
              <IonButton
                onClick={async () => {
                  if (method === UPLOAD_FLOW_TYPE.FILE) {
                    const file = await selectDocumentFile()
                    if (file) {
                      addFileToDocument(file)
                      history.push(
                        `${APP_PATH.UPLOAD.FILE}/${UPLOAD_PAGE_TYPE.MULTIPLE.value}${hubMessageId ? `/${hubMessageId}` : ''}`
                      )
                    }
                  } else if (method === UPLOAD_FLOW_TYPE.CAMERA) {
                    const photo = await getDocumentPhoto()
                    if (photo) {
                      setUploadedPhoto(photo)
                      history.push(
                        `${APP_PATH.UPLOAD.CAMERA}/${UPLOAD_PAGE_TYPE.MULTIPLE.value}${hubMessageId ? `/${hubMessageId}` : ''}`
                      )
                    }
                  }
                }}
                color="light"
                disabled={isSubmitting}
              >
                Add New Page
              </IonButton>
            </div>
            <SubmitButton color="secondary" submitting={isSubmitting}>
              Submit
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </div>
  )
}

DocumentManager.propTypes = exact(propTypes)
DocumentManager.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    files: selectors.files(state),
    method: selectors.method(state),
    title: selectors.title(state),
  }
}

const mapDispatchToProps = {
  addFileToDocument: actions.addFileToDocument,
  setUploadedPhoto: actions.setUploadedPhoto,
  removeFileFromDocument: actions.removeFileFromDocument,
  setTitle: actions.setTitle,
  postDocument: apiActions.postDocument,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DocumentManager
)
