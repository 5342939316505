import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import {
  RecentDocumentsCard,
  ConsultantContactCard,
  MetricsList,
  ProfitAndLossCard,
  PerDiemCard,
  SavingsCard,
  HubMessagesNotificationCard,
} from '../components'
import { MileageTrackerLink } from 'components'
import { isEmpty } from 'lodash'
import { IonCard, IonCardContent, IonList } from '@ionic/react'

const propTypes = {
  fetchLatestDocs: PropTypes.func.isRequired,
  fetchConsultantContact: PropTypes.func.isRequired,
  fetchHomeProfitAndLoss: PropTypes.func.isRequired,
  fetchHomePerDiem: PropTypes.func.isRequired,
  fetchHomeMileageSummary: PropTypes.func.isRequired,
  profitAndLoss: Types.profitAndLoss,
  perDiem: Types.perDiem,
  latestDocs: PropTypes.arrayOf(Types.doc),
  isRideShareUser: PropTypes.bool,
  savings: PropTypes.number,
  hasActiveHubMessages: PropTypes.bool.isRequired,
}

const defaultProps = {
  profitAndLoss: null,
  perDiem: null,
  latestDocs: null,
  isRideShareUser: false,
  savings: null,
}

function StandardUserHome({
  fetchLatestDocs,
  fetchConsultantContact,
  fetchHomeProfitAndLoss,
  fetchHomePerDiem,
  profitAndLoss,
  perDiem,
  latestDocs,
  isRideShareUser,
  fetchHomeMileageSummary,
  savings,
  hasActiveHubMessages,
}) {
  const [error, setError] = useState()
  useEffect(() => {
    const fetchYearData = async () => {
      try {
        await fetchHomeProfitAndLoss()
      } catch (errorMessage) {
        setError(
          `There was an issue loading your Profit & Loss data: ${errorMessage}`
        )
      }
    }

    fetchYearData()
  }, [fetchHomeProfitAndLoss])

  return (
    <div className="home-dashboard-wrapper">
      {!isEmpty(error) ? (
        <IonCard>
          <IonCardContent>
            <p className="centered-content">{error}</p>
          </IonCardContent>
        </IonCard>
      ) : (
        <>
          <MetricsList profitAndLoss={profitAndLoss} />
          <IonList className="metrics-card-list">
            {isRideShareUser ? (
              <SavingsCard
                savings={savings}
                fetchHomeMileageSummary={fetchHomeMileageSummary}
                setError={setError}
              />
            ) : (
              <>
                <ProfitAndLossCard profitAndLoss={profitAndLoss} />
                <PerDiemCard
                  fetchHomePerDiem={fetchHomePerDiem}
                  perDiem={perDiem}
                  setError={setError}
                />
              </>
            )}
          </IonList>
        </>
      )}
      {isRideShareUser && <MileageTrackerLink />}
      {hasActiveHubMessages && <HubMessagesNotificationCard />}
      <RecentDocumentsCard
        fetchLatestDocs={fetchLatestDocs}
        latestDocs={latestDocs}
      />
      <ConsultantContactCard fetchConsultantContact={fetchConsultantContact} />
    </div>
  )
}

StandardUserHome.propTypes = propTypes
StandardUserHome.defaultProps = defaultProps

export default React.memo(StandardUserHome)
