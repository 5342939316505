import PropTypes from 'prop-types'
import * as Types from 'types'
import { selectors as documentSelectors } from './documents/reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { useLocation, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { APP_PATH } from 'config'

const propTypes = {
  children: PropTypes.node.isRequired,
  files: PropTypes.arrayOf(Types.fileForUpload),
  uploadedPhoto: Types.photo,
}
const defaultProps = {
  files: null,
  uploadedPhoto: null,
}

function UploadFlowRedirect({ children, files, uploadedPhoto }) {
  const location = useLocation()
  const hasNothingToUpload = isEmpty(files) && !uploadedPhoto
  const { hubMessageId } = useParams()
  const correctPath = hubMessageId
    ? `${APP_PATH.HUB_MESSAGES.VIEW}/${hubMessageId}`
    : APP_PATH.HOME
  const isAlreadyCorrectPath = location.pathname === correctPath

  useEffect(() => {
    if (hasNothingToUpload && !isAlreadyCorrectPath) {
      // use href to force a full refresh of the navigation stack
      window.location.href = correctPath
    }
  }, [hasNothingToUpload, isAlreadyCorrectPath, correctPath])

  if (hasNothingToUpload) return null

  return children
}

UploadFlowRedirect.propTypes = propTypes
UploadFlowRedirect.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    files: documentSelectors.files(state),
    uploadedPhoto: documentSelectors.uploadedPhoto(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UploadFlowRedirect
)
