import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { IonButton, IonItem, IonLabel, IonIcon } from '@ionic/react'
import { useHistory, useParams } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../actions'
import { Form, Formik, Field } from 'formik'
import * as Yup from 'yup'
import { TextInput, SubmitButton, NoteModal } from 'components'
import { documentOutline } from 'ionicons/icons'
import { selectors } from '../reducer'
import { isEmpty, last } from 'lodash'
import * as apiActions from 'api-actions'
import { formatFilesPayloadFromValues } from 'utils'
import { useToast } from 'hooks'
import { UPLOAD_PAGE_TYPE, UPLOAD_FLOW_TYPE, APP_PATH } from 'config'

const propTypes = {
  files: PropTypes.arrayOf(Types.fileForUpload).isRequired,
  setCaptureMethod: PropTypes.func.isRequired,
  addNoteToFile: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  postDocument: PropTypes.func.isRequired,
}

const defaultProps = {}

function UploadFilesFlow({
  files,
  setCaptureMethod,
  addNoteToFile,
  setTitle,
  title,
  postDocument,
}) {
  const uploadedFile = last(files)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [note, setNote] = useState(uploadedFile.note ?? '')
  const { showFailureToast } = useToast()
  const history = useHistory()
  const { pageType, hubMessageId } = useParams()

  const isMultipleSelection = pageType === UPLOAD_PAGE_TYPE.MULTIPLE.value

  const validationSchema = Yup.object({
    title: Yup.string(),
    hubMessageId: Yup.string(),
  })

  const handleSubmit = async (values, { resetForm }) => {
    if (!isMultipleSelection) {
      // if single selection, submit immediately and redirect to success splash
      try {
        const valuesWithFiles = {
          ...values,
          files: [
            {
              blob: uploadedFile.blob,
              fileName: uploadedFile.fileName,
              note,
            },
          ],
        }
        const payload = formatFilesPayloadFromValues(valuesWithFiles)
        await postDocument(payload)

        resetForm()
        setNote('')
        history.push(
          `${APP_PATH.UPLOAD.SUCCESS}${values.hubMessageId ? `/${values.hubMessageId}` : ''}`
        )
      } catch (errorMessage) {
        showFailureToast({ message: errorMessage })
      }
    } else {
      // if multiple selection, add file to redux files and redirect to document manager
      addNoteToFile({ note, fileName: uploadedFile.fileName })
      setCaptureMethod(UPLOAD_FLOW_TYPE.FILE)
      setNote('')
      setTitle(values.title)
      history.push(
        `${APP_PATH.UPLOAD.DOCUMENT_MANAGER}${values.hubMessageId ? `/${values.hubMessageId}` : ''}`
      )
    }
  }

  return (
    <div className="splash-wrapper-with-documents ion-padding">
      <Formik
        initialValues={{ title, hubMessageId }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className="document-preview-state" noValidate>
            <Field type="hidden" name="hubMessageId" />

            {isEmpty(title) && (
              <TextInput
                name="title"
                label="Document Title (optional)"
                inputmode="text"
                disabled={isSubmitting}
                color="light"
              />
            )}
            {uploadedFile && (
              <IonItem lines="none" color="dark">
                <IonIcon slot="start" icon={documentOutline} />
                <IonLabel>{uploadedFile.fileName}</IonLabel>
              </IonItem>
            )}
            <IonButton
              id="open-note-modal"
              expand="block"
              fill="outline"
              color="light"
              disabled={isSubmitting}
              onClick={() => {
                setIsModalOpen(true)
              }}
            >
              Add Note
            </IonButton>
            <SubmitButton color="secondary" submitting={isSubmitting}>
              {isMultipleSelection ? 'Save' : 'Submit'}
            </SubmitButton>
          </Form>
        )}
      </Formik>
      <NoteModal
        note={note}
        setNote={setNote}
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </div>
  )
}

UploadFilesFlow.propTypes = exact(propTypes)
UploadFilesFlow.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    title: selectors.title(state),
    files: selectors.files(state),
  }
}

const mapDispatchToProps = {
  addNoteToFile: actions.addNoteToFile,
  setCaptureMethod: actions.setCaptureMethod,
  setTitle: actions.setTitle,
  postDocument: apiActions.postDocument,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UploadFilesFlow
)
