import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonIcon } from '@ionic/react'
import { MENU_ICON } from 'config'

const propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
}

const defaultProps = {
  icon: null,
}

function NavMenuItemLabelDefault({ icon, name }) {
  return (
    <>
      {icon && (
        <IonIcon slot="start" icon={MENU_ICON[icon]} aria-hidden={true} />
      )}
      {name}
    </>
  )
}

NavMenuItemLabelDefault.propTypes = exact(propTypes)
NavMenuItemLabelDefault.defaultProps = defaultProps

export default React.memo(NavMenuItemLabelDefault)
