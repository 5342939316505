import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  IonContent,
  IonPage,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react'
import {
  Header,
  UploadFAB,
  UploadModal,
  Footer,
  DataViewTypeButtons,
  PageTitle,
} from 'components'
import { selectors } from './reducer'
import { selectors as hubMessagesSelectors } from './hub-messages/reducer'
import * as actions from './actions'
import * as documentActions from './documents/actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { DATA_VIEW_TYPE, APP_PATH, INACTIVE_ACCESS_HOME_PATH } from 'config'
import * as Types from 'types'
import { first, isFunction, split } from 'lodash'
import { useLocation } from 'react-router-dom'
import { informationCircleOutline } from 'ionicons/icons'

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  showUploadCTA: PropTypes.bool,
  currentUser: Types.user.isRequired,
  hideBackButton: PropTypes.bool,
  headerTitle: PropTypes.string,
  htmlTitle: PropTypes.string,
  isWelcomeHeader: PropTypes.bool,
  backHref: PropTypes.string,
  showDataViewTypeButtons: PropTypes.bool,
  isUploadModalOpen: PropTypes.bool.isRequired,
  setIsUploadModalOpen: PropTypes.func.isRequired,
  addFileToDocument: PropTypes.func.isRequired,
  setUploadedPhoto: PropTypes.func.isRequired,
  infoModalTriggerId: PropTypes.string,
  hasActiveHubMessages: PropTypes.bool.isRequired,
}
const defaultProps = {
  showUploadCTA: false,
  hideBackButton: false,
  headerTitle: null,
  htmlTitle: null,
  isWelcomeHeader: false,
  backHref: APP_PATH.HOME,
  showDataViewTypeButtons: false,
  infoModalTriggerId: '',
}

function Layout({
  children,
  showUploadCTA,
  currentUser,
  headerTitle,
  htmlTitle,
  hideBackButton,
  isWelcomeHeader,
  backHref,
  showDataViewTypeButtons,
  isUploadModalOpen,
  setIsUploadModalOpen,
  addFileToDocument,
  setUploadedPhoto,
  infoModalTriggerId,
  hasActiveHubMessages,
}) {
  const { pathname } = useLocation()
  const [selectedDataViewType, setSelectedDataViewType] = useState(
    DATA_VIEW_TYPE.CHART
  )

  const hasInactiveAccess = !currentUser.isActive
  const isHomeForInactiveAccess =
    hasInactiveAccess && pathname === INACTIVE_ACCESS_HOME_PATH
  const firstName = first(split(currentUser.fullName, ' '))
  const title = isWelcomeHeader ? `Welcome Back, ${firstName}!` : headerTitle

  return (
    <IonPage>
      <PageTitle title={htmlTitle || title} />
      <Header
        hideBackButton={hideBackButton || isHomeForInactiveAccess}
        title={title}
        backHref={backHref}
        logoPath={hasInactiveAccess ? INACTIVE_ACCESS_HOME_PATH : APP_PATH.HOME}
        hasActiveHubMessages={hasActiveHubMessages}
      >
        {showDataViewTypeButtons && (
          <DataViewTypeButtons
            setSelectedDataViewType={setSelectedDataViewType}
            isChartDataViewType={selectedDataViewType === DATA_VIEW_TYPE.CHART}
          />
        )}
        {infoModalTriggerId && (
          <IonButtons slot="primary">
            <IonButton
              id={infoModalTriggerId}
              fill="clear"
              size="small"
              aria-label={`Learn more about ${title}`}
            >
              <IonIcon slot="icon-only" icon={informationCircleOutline} />
            </IonButton>
          </IonButtons>
        )}
      </Header>
      <IonContent className="layout-content-wrapper">
        <div className="layout-content">
          {showDataViewTypeButtons && isFunction(children)
            ? children({ selectedDataViewType, setSelectedDataViewType })
            : children}
          {showUploadCTA && (
            <UploadFAB setIsOpen={setIsUploadModalOpen}></UploadFAB>
          )}
          <UploadModal
            isOpen={isUploadModalOpen}
            setIsOpen={setIsUploadModalOpen}
            addFileToDocument={addFileToDocument}
            setUploadedPhoto={setUploadedPhoto}
          />
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: selectors.currentUser(state),
    isUploadModalOpen: selectors.isUploadModalOpen(state),
    hasActiveHubMessages: hubMessagesSelectors.hasActiveHubMessages(state),
  }
}

const mapDispatchToProps = {
  setIsUploadModalOpen: actions.setIsUploadModalOpen,
  addFileToDocument: documentActions.addFileToDocument,
  setUploadedPhoto: documentActions.setUploadedPhoto,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
