import { isToday, format } from 'date-fns'

function displayHubMessageDate(dateTime) {
  const date = new Date(dateTime)
  return isToday(date)
    ? `${format(date, 'h:mm a')}, Today`
    : format(date, 'MMM d, yyyy')
}

export default displayHubMessageDate
