import React from 'react'
import exact from 'prop-types-exact'
import { IonCard, IonCardHeader, IonCardContent, IonImg } from '@ionic/react'
import { displayHubMessageDate } from 'utils'
import userImg from 'images/user.svg'
import PropTypes from 'prop-types'

const propTypes = {
  response: PropTypes.string.isRequired,
  responseSentAt: PropTypes.string.isRequired,
}

const defaultProps = {}

function HubMessageReply({ response, responseSentAt }) {
  const dateDisplay = displayHubMessageDate(responseSentAt)
  return (
    <IonCard>
      <IonCardHeader className="hub-message-ion-card-header hub-message-document-header">
        <div className="hub-message-show-header">
          <IonImg src={userImg} className="user-img" alt="" />
          <div>{dateDisplay}</div>
        </div>
      </IonCardHeader>
      <IonCardContent className="hub-message-show-content hub-message-document-content">
        {response}
      </IonCardContent>
    </IonCard>
  )
}

HubMessageReply.propTypes = exact(propTypes)
HubMessageReply.defaultProps = defaultProps

export default React.memo(HubMessageReply)
