import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonButton, IonIcon, IonLabel } from '@ionic/react'
import { addCircleOutline } from 'ionicons/icons'
import classNames from 'classnames'

const propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  useHubMessageStyle: PropTypes.bool,
}

const defaultProps = {
  useHubMessageStyle: false,
}

function UploadFAB({ setIsOpen, useHubMessageStyle }) {
  const label = useHubMessageStyle ? 'Upload' : 'Upload Documents'
  return (
    <IonButton
      className={classNames('action-button', {
        'hub-message-button hub-message-non-archive-button': useHubMessageStyle,
      })}
      onClick={() => setIsOpen(true)}
      type="button"
      color={useHubMessageStyle ? 'secondary' : undefined}
    >
      {!useHubMessageStyle && (
        <IonIcon icon={addCircleOutline} slot="start"></IonIcon>
      )}

      <IonLabel slot="end" color={useHubMessageStyle ? 'primary' : undefined}>
        {label}
      </IonLabel>
    </IonButton>
  )
}

UploadFAB.propTypes = exact(propTypes)
UploadFAB.defaultProps = defaultProps

export default React.memo(UploadFAB)
