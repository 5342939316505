import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  IonCard,
  IonCardContent,
  IonList,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react'
import { isEmpty, map } from 'lodash'
import { PaginatedItems, LoadingSpinner } from 'components'

const propTypes = {
  dataFilter: PropTypes.string.isRequired,
  setDataFilter: PropTypes.func.isRequired,
  filteredData: PropTypes.arrayOf(
    PropTypes.oneOfType([Types.hubMessage, Types.doc])
  ),
  categories: PropTypes.arrayOf(Types.filteredTableCategory).isRequired,
  noFilteredTableContent: PropTypes.elementType.isRequired,
  itemComponent: PropTypes.elementType.isRequired,
  getItemProps: PropTypes.func.isRequired,
}

const defaultProps = {
  filteredData: null,
}

function FilteredContentTable({
  dataFilter,
  setDataFilter,
  filteredData,
  categories,
  noFilteredTableContent: NoFilteredTableContent,
  itemComponent: ItemComponent,
  getItemProps,
}) {
  if (!filteredData) {
    return <LoadingSpinner />
  }
  return (
    <div className="filtered-content-table-wrapper">
      <IonSegment
        onIonChange={(e) => setDataFilter(e.detail.value)}
        value={dataFilter}
        mode="ios"
      >
        {map(categories, (categoryObj) => {
          const { categoryValue, categoryLabel } = categoryObj
          return (
            <IonSegmentButton key={categoryValue} value={categoryValue}>
              <IonLabel>{categoryLabel}</IonLabel>
            </IonSegmentButton>
          )
        })}
      </IonSegment>
      <IonCard id="filtered-content-table-card">
        <IonCardContent className="ion-text-center">
          <IonList>
            {isEmpty(filteredData) ? (
              <NoFilteredTableContent />
            ) : (
              <PaginatedItems
                key={dataFilter}
                items={filteredData}
                itemComponent={({ item }) => (
                  <ItemComponent {...getItemProps(item)} />
                )}
              />
            )}
          </IonList>
        </IonCardContent>
      </IonCard>
    </div>
  )
}

FilteredContentTable.propTypes = exact(propTypes)
FilteredContentTable.defaultProps = defaultProps

export default React.memo(FilteredContentTable)
