import React from 'react'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react'
import { APP_PATH } from 'config'
function HubMessagesNotificationCard() {
  return (
    <IonCard
      className="hub-messages-notification-card default-card-styles"
      routerLink={`${APP_PATH.HUB_MESSAGES.ROOT}`}
    >
      <div>
        <IonCardHeader>
          <IonCardTitle>
            <span className="alert-dot"></span>New Message(s)
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent color="dark">
          You have one or more new messages
        </IonCardContent>
      </div>
      <IonButton fill="outline" color="dark">
        View
      </IonButton>
    </IonCard>
  )
}

export default React.memo(HubMessagesNotificationCard)
