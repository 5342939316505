import React from 'react'
import exact from 'prop-types-exact'
import * as Types from 'types'
import { IonItem } from '@ionic/react'
import { APP_PATH } from 'config'
import {
  displayHubMessageDate,
  shouldDisplayHubMessageActionNeeded,
} from 'utils'
import classnames from 'classnames'

const propTypes = {
  hubMessage: Types.hubMessage.isRequired,
}

const defaultProps = {}

function HubMessageLineItem({ hubMessage }) {
  const hasBeenRead = !!hubMessage.readAt
  const shouldDisplayActionNeeded =
    shouldDisplayHubMessageActionNeeded(hubMessage)

  const dateDisplay = displayHubMessageDate(hubMessage.createdAt)

  return (
    <IonItem
      lines="full"
      detail="false"
      routerLink={`${APP_PATH.HUB_MESSAGES.VIEW}/${hubMessage.id}`}
    >
      <div className="table-content-line-item-wrapper hub-message-line-item-wrapper ion-justify-content-between">
        <div className="table-content-text-block hub-message-line-item-text-block">
          <div className="table-content-line-item-subtitle hub-message-line-item-subtitle">
            {dateDisplay}
          </div>
          {!hasBeenRead && <span className="alert-dot"></span>}
          <div className="hub-message-line-item-text-wrapper">
            <h2
              className={classnames('table-content-line-item-title', {
                'archived-line-item': hasBeenRead,
              })}
            >
              {hubMessage.subject}
            </h2>
            {shouldDisplayActionNeeded && (
              <div className="message-action-needed-badge">Action Needed</div>
            )}
          </div>
        </div>
      </div>
    </IonItem>
  )
}

HubMessageLineItem.propTypes = exact(propTypes)
HubMessageLineItem.defaultProps = defaultProps

export default React.memo(HubMessageLineItem)
