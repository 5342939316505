import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import noNewHubMessagesIcon from 'images/MESSAGES-NO-NEW.svg'
import newHubMessagesIcon from 'images/MESSAGES-NEW.svg'
import { IonIcon } from '@ionic/react'
import { HUB_MESSAGE_DASHBOARD_LABEL } from 'config'

const propTypes = {
  activeHubMessagesCount: PropTypes.number.isRequired,
}

const defaultProps = {}

function NavMenuItemLabelHubMessage({ activeHubMessagesCount }) {
  const activeHubMessagesPresent = activeHubMessagesCount > 0
  const hubMessagesIcon = activeHubMessagesPresent
    ? newHubMessagesIcon
    : noNewHubMessagesIcon

  const name = activeHubMessagesPresent
    ? `${HUB_MESSAGE_DASHBOARD_LABEL} (${activeHubMessagesCount})`
    : HUB_MESSAGE_DASHBOARD_LABEL
  return (
    <>
      <IonIcon slot="start" icon={hubMessagesIcon} aria-hidden={true} />
      {name}
    </>
  )
}

NavMenuItemLabelHubMessage.propTypes = exact(propTypes)
NavMenuItemLabelHubMessage.defaultProps = defaultProps

export default React.memo(NavMenuItemLabelHubMessage)
